@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #6950ba transparent;
}

*::-webkit-scrollbar {
  height: 3px !important;
  width: 5px !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #6950ba;
  border: transparent;
  border-radius: 25px;
}

body {
  overflow-x: hidden !important;
}

/* reset start */
.btn:focus-visible {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: none !important;
}

/* reset end */
/* common(global) start */
.modal-backdrop.show {
  z-index: 6 !important;
}
.modal {
  z-index: 7 !important;
}
.pac-container.pac-logo {
  z-index: 8 !important;
}
.pac-container.pac-logo::after {
  content: none !important;
}
.backdrop_loading.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;
}

.backdrop_loading .modal-content {
  background: none;
  border: none;
}

.btn_primary,
.btn_error {
  color: #ffffff;
  border-radius: 10px;
  padding: 14px 2rem;
  font-weight: 500;
  border: none;
  outline: none;
}

.btn_primary {
  background: #6950ba;
}

.btn_error {
  background: #e03616;
}

.btn_primary:hover {
  background: #624baf;
  color: #ffffff;
}

.btn_error:hover {
  background: #d53515;
  color: #ffffff;
}

.btn_primary:disabled {
  background: #c3b9e3;
  cursor: not-allowed;
}

.btn_primary_light {
  color: #000;
  border-radius: 10px;
  padding: 14px 2rem;
  font-weight: 600;
  border: none;
  outline: none;
  background: #b7e0ff;
}

.btn_primary_light:hover {
  background: #b3dbf9;
}

.btn_primary_outline_box {
  background: transparent;
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #6950ba;
  cursor: pointer;
}

.btn_primary_outline_box:hover {
  background: #624baf;
  color: #ffffff;
}

.btn_primary_outline {
  border-radius: 12px;
  border: 1px solid #6950ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6950ba;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 14px;
  transition: 0.2s all ease;
}

.btn_primary_outline:hover {
  background: #6950ba;
  color: #ffffff;
}

.btn_danger_sm {
  border-radius: 9px;
  border: 1px solid #e03616;
  background: #e03616;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding: 3px 14px;
  transition: 0.2s all ease;
}

.btn_danger_sm:hover {
  background: #d63515;
}
.btn_danger_outline {
  border-radius: 12px;
  border: 1px solid #e03616;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e03616;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 14px;
  transition: 0.2s all ease;
}

.btn_danger_outline:hover {
  background: #e03616;
  color: #ffffff;
}
.btn_warn_outline_sm {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  color: #ffd251;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #ffd251;
  border-radius: 7px;
  width: max-content;
}
.btn_warn_outline_sm:hover {
  background-color: #ffd251;
  color: #6950ba;
}
.btn_warn_outline_sm:hover svg {
  stroke: #6950ba;
  fill: #6950ba;
}
.btn_light_outline_sm {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #fff;
  border-radius: 7px;
  width: max-content;
}
.btn_light_outline_sm:hover {
  background-color: #fff;
  color: #6950ba;
}
.btn_light_outline_sm:hover svg {
  stroke: #6950ba;
  fill: #6950ba;
}

.btn_readmore {
  color: #6950ba;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.btn_readmore:hover {
  color: #403469;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.toggle {
  position: relative;
  display: block;
  width: 34px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  font-size: 10px;
  display: block;
  background: #e6e6e6;
  border-radius: 8px;
  -webkit-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(105, 80, 186, 0.5);
  border-radius: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.toggle_switch:checked + .toggle:before {
  background: #cdc7e0;
}

.toggle_switch:checked + .toggle span {
  background: #6950ba;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  -o-transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  -webkit-box-shadow: 0 3px 8px rgba(105, 80, 186, 0.2);
  box-shadow: 0 3px 8px rgba(105, 80, 186, 0.2);
}

.toggle_switch:checked + .toggle span:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.input_time {
  height: 55px;
  position: relative;
  display: flex;
}
.input_time > input {
  width: 100%;
  height: 100%;
  color: #000;
}
.input_time > input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}
.input_time > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  z-index: 1;
  user-select: none;
}
.select_dropdown.city_state_dropdown .dropdown-menu {
  max-height: 350px;
  overflow-y: scroll;
}
.select_dropdown button {
  color: #201838 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  border-radius: 12px;
  border: 1px solid #e4e3e7 !important;
  background: #fff !important;
  padding: 18px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 55px;
}
.select_dp_read button {
  cursor: default !important;
}
.select_dp_read button::after {
  content: none;
}
.select_dropdown button > span {
  color: #8a8792 !important;
  font-weight: 400;
}
.select_dropdown_sm button {
  max-height: 36px;
}

.dropdown_w_icon button {
  padding: 15px 16px;
}

.select_dropdown button.btn-check:checked + .btn,
.select_dropdown button.btn.active,
.select_dropdown button.btn.show,
.select_dropdown button.btn:first-child:active,
.select_dropdown button:not(.btn-check) + .btn:active {
  color: #8a8792;
  border: 1px solid #e4e3e7;
  background: #fff;
}

.select_dropdown .dropdown-menu {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  z-index: 5;
}

.select_dropdown .dropdown-menu .dropdown-item {
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.056px;
}

.select_dropdown .dropdown-menu .dropdown-item.active,
.select_dropdown .dropdown-menu .dropdown-item:active {
  background: #fff;
  color: #8a8792;
}

.select_pet_dropdown,
.select_petprofile_dropdown {
  display: flex;
  align-items: center;
  gap: 12px;
}

.select_petprofile_dropdown div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
}

.select_petprofile_dropdown div:first-child img {
  width: 100%;
  object-fit: contain;
}

.select_pet_dropdown div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.select_pet_dropdown div:first-child img {
  width: 100%;
}

.select_pet_dropdown div:last-child,
.select_petprofile_dropdown div:last-child {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.select_petprofile_dropdown span {
  color: #201838;
  font-weight: 600;
}

.select_petprofile_dropdown p {
  color: #635d74;
  font-size: 12px;
  margin: 0;
}

.select_pet_dropdown span {
  color: #201838;
  font-size: 14px;
}

.date_selector_custom {
  position: relative;
}

.date_selector_custom .select-date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 15px 20px;
  max-height: 55px;
  background-color: #fff;
}

.date_selector_custom .select-date-header span {
  color: #8a8792;
  font-size: 14px;
}

.date_selector_custom .select-date-header p {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.date_selector_custom .date_calander {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: calc(100% + 5px);
}

.date_selector_custom .date_calander.noOpen {
  display: none !important;
}

.date_selector_custom .react-datepicker {
  width: 100%;
  padding: 0 8px;
  border: none;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
}

.date_selector_custom .react-datepicker__month-container {
  width: 100%;
}

.date_selector_custom .react-datepicker__header {
  background-color: #fff;
  border: none;
  padding: 20px 0;
}

.date_selector_custom .react-datepicker__current-month {
  margin-bottom: 20px;
}

.react-datepicker__custom-header {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0 0 10px;
}

.react-datepicker__select--year,
.react-datepicker__select--month {
  font-size: 16px;
  color: rgb(119, 119, 119);
  font-weight: 600;
  border: 1px solid rgb(228, 227, 231);
  padding: 4px 8px;
  border-radius: 8px;
}

.date_selector_custom .react-datepicker__navigation--previous {
  top: 24px;
  left: 8px;
}

.date_selector_custom .react-datepicker__navigation--next {
  top: 24px;
  right: 8px;
}

.date_selector_custom .react-datepicker__day-names {
  display: flex;
  margin-top: 20px;
}

.date_selector_custom .react-datepicker__week {
  display: flex;
}

.date_selector_custom .react-datepicker__day-name,
.date_selector_custom .react-datepicker__day,
.date_selector_custom .react-datepicker__time-name {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  margin: 2px 0;
}

.date_selector_custom .react-datepicker__day-name {
  color: #201838;
}

.date_selector_custom .react-datepicker__day {
  color: #635d74;
  height: 36px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.date_selector_custom .react-datepicker__day > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 16px;
}

.date_selector_custom .react-datepicker__day > div > div {
  width: 100%;
  height: 13px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date_selector_custom .react-datepicker__day--keyboard-selected,
.date_selector_custom .react-datepicker__day--selected:hover,
.date_selector_custom .react-datepicker__day--selected {
  border-radius: 8px !important;
  background-color: #6950ba !important;
  color: #fff !important;
  font-weight: 600;
  border: none;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  /* cursor: not-allowed !important; */
  color: #ccc !important;
}

/* common(global) end */

/* navbar start */
.header_navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
}

.navbar_top {
  background-color: #f0f0f0;
  font-weight: 500;
  max-height: 52px;
}

.navbar_top p {
  margin: 14px 0;
}

.navbar_top p span {
  color: #6950ba;
}

.floating_social_btn {
}

.floating_social_btn span {
  cursor: pointer;
}

.floating_social_btn .insta_btn {
  margin-right: 20px;
}

.floating_social_btn .mail_btn {
  display: flex;
  align-items: center;
}

.floating_social_btn .mail_btn::before {
  content: "";
  display: block;
  height: 20px;
  width: 1px;
  background: #8a8792;
  margin-right: 20px;
}

.floating_social_btn button {
  border: none;
  background: #b7e0ff;
  padding: 14px 16px;
  border-radius: 0 0 8px 8px;
  margin-left: 20px;
  font-weight: 700;
  font-size: 20px;
  z-index: 2;
  cursor: pointer;
}

nav.navbar {
  background-color: #ffffff !important;
  padding: 1rem;
  z-index: 1;
}

.navbar-brand > div > img {
  height: 40px !important;
}

.header_navbar .nav-link {
  margin: auto 13px;
  position: relative;
}

.navbar_location_search {
  display: flex;
  justify-content: start;
  align-items: center;
}

.navbar_location_box {
  display: flex;
  align-items: center;
  border: 1px solid #e1e1ec;
  padding: 12px 18px;
  border-radius: 8px;
  max-width: 250px;
  width: 100%;
}

.navbar_location_box span {
  font-size: 15px;
  font-weight: 600;
  margin: 0 8px;
}

.navbar_search_box {
  position: relative;
  width: 545px;
  border: none !important;
  outline: none;
  background: none !important;
  /* width: 100%; */
}

.dropdown .navbar_search_box::after {
  display: none;
}

.navbar_search_box input {
  width: 100%;
  height: 100%;
  padding: 15px 50px;
  border: 1px solid #e1e1ec;
  border-radius: 8px;
  background: none;
  outline: none;
}

.navbar_search_box input:hover {
  border-color: #cbcacc;
}

.navbar_search_box input:focus {
  border-color: #6950ba;
}

.navbar_search_box svg {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.navbar_search_box_dropdown.dropdown-menu {
  max-height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 1rem;
  width: calc(100% - 1rem);
  border: 1px solid #e4e3e7;
  min-width: 520px;
}

.navbar_search_box_dropdown .dropdown-item {
  font-size: 12px;
  font-weight: 600;
  color: #635d74;
  padding: 8px 16px;
  line-height: 12px;
  display: flex;
  align-items: center;
}

.navbar_search_box_dropdown .dropdown-item.active,
.navbar_search_box_dropdown .dropdown-item:active {
  background-color: #6950ba;
}

.navbar_search_box_dropdown .dropdown-item.active svg,
.navbar_search_box_dropdown .dropdown-item:active svg {
  filter: brightness(10);
}

.navbar_search_box_dropdown .dropdown-item span {
  margin-left: auto;
}

.navbar_search_box_dropdown p {
  font-size: 16px;
  font-weight: 500;
  color: #201838;
  padding: 8px 16px;
  margin: 0;
}

.navbar_search_box_dropdown p.inner_title {
  font-weight: 600;
}

.doctors_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  width: 100%;
}
.doctors_box {
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  border: 0.83px solid #e4e3e7;
  padding: 13px;
}
.box_top .personal_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.personal_details .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.personal_details .left .profile {
  width: 45px;
  height: 45px;
  min-width: 45px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.personal_details .left .profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.personal_details .left .name {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #201838;
}
.personal_details .left .name .rating {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: #201838;
  margin-top: 4px;
}
.personal_details .left .name .rating .rating_icon {
  width: 16px;
  margin-right: 4px;
}
.personal_details .left .name .rating .rating_icon img {
  width: 100%;
}
.career_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #c3b9e3;
}
.career_details .career_box {
  flex: 1;
}
.career_details .career_box .title {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #635d74;
}
.career_details .career_box .count {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #201838;
  margin-top: 2px;
}
.box_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.box_bottom .bottom_img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog_wrapper {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.blog_wrapper .blog_box {
  width: 50%;
}
.blog_wrapper .blog_box .blog_img {
  width: 100%;
  height: 156px;
  border-radius: 18px;
  overflow: hidden;
  margin-bottom: 8px;
}
.blog_wrapper .blog_box .blog_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog_wrapper .blog_box .blog_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #201838;
}
.blog_wrapper .blog_box .blog_time {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #635d74;
  margin-top: 4px;
}

nav .navbar-collapse {
  flex-grow: 0;
}

.nav-link:hover {
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
}

.header_navbar .nav-link span,
.header_navbar .navbar_cart_box_sm a span {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e03616;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  top: -6px;
  right: -4px;
}

.no_data {
  background-color: #fbd58c;
  padding: 33px 0;
  margin: 5px;
}
.no_data .no_data_content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.no_data .no_data_content .title {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  color: #201838;
  width: 70%;
}
.no_data .no_data_content .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #20183880;
  margin: 16px 0;
  width: 60%;
}
.no_data .no_data_content .action {
}
.no_data .no_data_content .action button {
  background-color: #201838;
  border: 1px solid #201838;
  border-radius: 6px;
  padding: 12px 27px;
  color: #ffffff;
  transition: 0.3s all;
}
.no_data .no_data_content .action button:hover {
  background-color: #ffffff;
  border: 1px solid #201838;
  color: #201838;
}
.no_data .no_data_image {
}
.no_data .no_data_image img {
  width: 100%;
}

.navbar_auth_profile_btn > button {
  background: none;
  color: #6950ba;
  border-color: #6950ba;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  margin-left: 20px;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.navbar_auth_profile_btn > button::after {
  width: 100%;
  height: 16px;
  padding: 4px 8px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(./assets/svg/arrow_down_primary.svg);
  background-repeat: no-repeat;
}

.navbar_auth_profile_btn.isnotloggedin > button::after {
  display: none;
}

.navbar_auth_profile_btn > button.show,
.navbar_auth_profile_btn > button:active,
.navbar_auth_profile_btn > button:focus,
.navbar_auth_profile_btn > button:hover {
  color: #ffffff;
  background-color: #6950ba !important;
}

.navbar_auth_profile_btn > button.show span *,
.navbar_auth_profile_btn > button:active span *,
.navbar_auth_profile_btn > button:focus span *,
.navbar_auth_profile_btn > button:hover span * {
  stroke: #ffffff;
  transition: all 0.2s ease-in-out;
}

.navbar_auth_profile_btn > button.show::after,
.navbar_auth_profile_btn > button:active::after,
.navbar_auth_profile_btn > button:focus::after,
.navbar_auth_profile_btn > button:hover::after {
  filter: brightness(5);
  transition: all 0.2s ease-in-out;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown {
  padding: 18px 0;
  border: none;
  left: unset !important;
  right: 0;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item {
  padding: 6px 24px 6px 16px;
  color: #201838;
  font-size: 14px;
  font-weight: 600;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item.active,
.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item:active {
  background-color: #6950ba;
  color: #ffffff;
}

.humburger_menu {
  display: none;
}

.navbar_links {
  background-color: #6950ba;
}

.navbar_links ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding-left: 0;
  color: #ffffff;
  margin-bottom: 0;
}

.navbar_links ul li {
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: 25px;
}

.navbar_links ul li:hover {
  color: #e5e5e5;
}

.navbar_links li.navlink_dropdown_btn {
  padding: 0;
}

li.navlink_dropdown_btn .dropdown-toggle {
  padding: 10px 0;
  line-height: normal;
  border-radius: 0;
  border: none;
  line-height: 25px;
  font-weight: 500;
  background-color: transparent;
  transition: none;
}

li.navlink_dropdown_btn .dropdown-toggle:hover {
  color: unset;
  background-color: transparent;
}

li.navlink_dropdown_btn .dropdown-toggle::after {
  content: unset;
}

.navbar_location_search_sm {
  display: none;
}

.navlink_dropdown_btn .navlink_dropdown_wo_icon {
  padding: 15px 16px;
  border-radius: 14px;
  min-width: 295px;
  border: none;
  inset: -2px auto auto 0px !important;
}

.navlink_dropdown_btn .navlink_dropdown_wo_icon .dropdown-item {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  color: #201838;
  font-size: 16px;
  font-weight: 600;
}

.navlink_dropdown_btn .navlink_dropdown_wo_icon .dropdown-item.active,
.navlink_dropdown_btn .navlink_dropdown_wo_icon .dropdown-item:active {
  color: #000;
  background-color: unset;
}

.navlink_dropdown_btn .navlink_dropdown_wo_icon .dropdown-item + .dropdown-item {
  margin-top: 12px;
}

.navlink_dropdown_btn .navlink_dropdown_list {
  padding: 18px 0;
  border-radius: 14px;
  min-width: 260px;
  border: none;
  inset: -2px auto auto 0px !important;
  cursor: default;
}

.navlink_dropdown_btn .navlink_dropdown_list .dropdown-item {
  color: #201838;
  font-size: 14px;
  font-weight: 500;
}

.navlink_dropdown_btn .navlink_dropdown_list .dropdown-item img {
  max-width: 32px;
  min-width: 32px;
}

.navlink_dropdown_btn .navlink_dropdown_list .dropdown-item:active {
  background-color: #f8f9fa;
}

.navlink_dropdown_btn .navlink_dropdown_list .dropdown-item + .dropdown-item {
  margin-top: 5px;
}

.navlink_dropdown_btn .navlink_dropdown_multiplelist {
  padding: 18px;
  border-radius: 14px;
  min-width: 260px;
  border: none;
  inset: -2px auto auto 0px !important;
  cursor: default;
}

.navlink_dropdown_btn .navlink_dropdown_multiplelist.show {
  display: flex !important;
}

.navlink_dropdown_btn .navlink_dropdown_multiplelist .navlink_dropdown_list {
  padding: 0;
}

.navlink_dropdown_btn .navlink_dropdown_multiplelist p {
  color: #201838;
  font-weight: 600;
}

.navlink_dropdown_btn .navlink_dropdown_multiplelist .dropdown-item {
  padding: 4px 0;
}

/* navbar end */

/* footer start */
footer section {
  background-color: #201838;
  padding: 194px 0 160px;
}

.footer_app_card_wrapper{
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  gap: 50px;
}

.footer_app_card_wrapper .footer_app_card_info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer_app_card_wrapper .footer_app_card_info p{
  width: 51%;
}

.footer_app_card_wrapper .footer_app_card_qrimg{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer_app_card {
  max-height: 160px;
}

.footer_app_card .card {
  background-color: #6950ba;
  border-radius: 25px;
  padding: 2rem;
}

.footer_app_card div.card .footer_app_card_info {
  color: #ffffff;
}

.footer_app_card div.card .footer_app_card_info h4 {
  font-weight: 700;
  font-size: 24px;
  max-width: 368px;
}

.footer_app_card div.card .footer_app_card_info p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 25px;
}

.footer_app_card div.card .footer_app_card_info > div img {
  max-width: 170px;
  width: 100%;
}

.footer_app_card .footer_app_card_info a + a img {
  margin-left: 2rem;
}

.footer_logo_newsletter {
  color: #ffffff;
}

.newsletter_heading {
  font-size: 22px;
  font-weight: 600;
  margin: 30px 0 5px;
}

.newsletter_desc {
  font-weight: 300;
  opacity: 0.8;
}

.newsletter_input {
  background: #ffffff;
  max-width: 475px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  margin: 15px 0 30px;
}

.newsletter_input input {
  width: 100%;
  font-weight: 500;
  color: #7e879f;
  padding-left: 20px;
  border: none;
  outline: none;
}

.footer_social_link p {
  font-size: 22px;
  font-weight: 600;
}

.footer_social_link span {
  display: flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.footer_social_link span svg {
  filter: saturate(0) brightness(3);
  margin: auto;
}

.footer_social_link span + span svg {
  filter: saturate(0) brightness(2.5);
}

.footer_social_link span:hover {
  opacity: 0.8;
}

footer .footer_links {
  color: #ffffff;
}

footer .footer_links span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 22px;
  /* padding-bottom: 16px; */
}

footer .footer_links span::after {
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  margin: 18px 0 24px;
  background-color: #6950ba;
}

footer .footer_links ul {
  list-style: none;
  padding: 0;
  font-weight: 500;
  margin: 0;
}

footer .footer_links li {
  cursor: pointer;
}

footer .footer_links li + li {
  margin-top: 20px;
}

/* footer end */

/* main section start */

/* main section end */
/* breadcrumb section start */
section.breadcrumb {
  background: #f0edf8;
}

.breadcrumb h5 {
  color: #201838;
  font-size: 20px;
  font-weight: 700;
}

.breadcrumb ul,
ul.route_view {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  align-items: center;
  margin: 0;
}

ul.route_view {
  margin: 40px 0 20px;
}

.breadcrumb li,
ul.route_view li {
  color: #201838;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.breadcrumb .breadcrumb_navlink_arrow,
ul.route_view .breadcrumb_navlink_arrow {
  pointer-events: none;
}

.breadcrumb .breadcrumb_navlink_arrow svg,
ul.route_view .breadcrumb_navlink_arrow svg {
  width: 18px;
  height: 18px;
}

.breadcrumb .breadcrumb_navlink_active,
ul.route_view .breadcrumb_navlink_active {
  color: #635d74;
  opacity: 0.8;
}

.breadcrumb li:hover,
ul.route_view li:hover,
.breadcrumb .breadcrumb_navlink_active:hover,
ul.route_view .breadcrumb_navlink_active:hover {
  color: #6950ba;
  opacity: 1;
  cursor: pointer;
}

/* breadcrumb section end */
/* status-ribbon section start */
.status_ribbon_success {
  background: #2d9d61;
}

.status_ribbon_fail {
  background: #e03616;
}

.status_ribbon_success h6,
.status_ribbon_fail h6 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 10px 5px;
}

.status_ribbon_success p,
.status_ribbon_fail p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 10px 20px;
}

/* status-ribbon section end */

/* home page start */
/* hero (slider) section start */

.slider_info > div {
  max-width: 460px;
  text-align: left;
  color: #4a2319;
}

.slider_info h1 {
  font-size: 48px;
  font-weight: 700;
}

.slider_info p {
  font-weight: 500;
  opacity: 0.6;
  margin: 16px 0 32px;
}

.btn_light {
  background-color: #ffffff;
  padding: 12px 40px;
  border-radius: 6px;
  font-weight: 600;
  border: none;
}

.carousel p.carousel-status {
  display: none;
}

.carousel ul.control-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50px;
}

.carousel ul.control-dots .dot {
  opacity: 1;
  filter: none;
  box-shadow: none;
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
}

.carousel ul.control-dots .dot.selected {
  background: #6950ba;
  width: 10px;
  height: 10px;
}

.carousel ul.control-dots .dot:hover {
  background: #6950ba;
}

.slider_content {
  position: relative;
}

.slider_content .container {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider_content .slider_image img {
  max-width: 390px;
}

/* hero (slider) section end */

/* veterinary start */
.service_veterinary {
  background-image: linear-gradient(to bottom right, #ffc9d82b, #fff6f82b);
  transform: translateY(-40px);
}

.veterinary_card_container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 22px 30px !important;
  box-shadow: 0px 10px 15px #635d7420;
}

.veterinary_card {
  padding: 40px 0 32px;
  height: 100%;
  border: 1px solid #e4e3e7;
  border-radius: 16px;
  transition: all 0.2s ease-in-out;
}

.veterinary_card:hover {
  cursor: pointer;
  border-color: #635d7450;
  box-shadow: 0px 0px 20px #635d7410;
}

.consultation_veterinary_card {
  background: linear-gradient(#f1ecff, #f1ecff00);
}

.pharmacy_veterinary_card {
  background-image: linear-gradient(#ffeee2, #ffeee200);
}

.homecare_veterinary_card {
  background-image: linear-gradient(#feffc8, #feffdf00);
}

.vaccination_veterinary_card {
  background-image: linear-gradient(#f5d9ff, #f1ecff00);
}

.bloodtest_veterinary_card {
  background-image: linear-gradient(#edffdc, #edffdc00);
}

.healthcard_veterinary_card {
  background-image: linear-gradient(#feffc8, #feffc800);
}

.veterinary_card span {
  font-weight: 600;
}

/* veterinary end */

/* services start */
.services_section > span {
  font-size: 20px;
  background: -webkit-linear-gradient(#eb155e, #9138be);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  display: block;
  margin: 100px 0 10px;
}

.services_section > h2 {
  text-align: center;
  max-width: 490px;
  font-weight: 700;
  font-size: 36px;
  margin: auto;
}

.shop_by_pet_card {
  background-color: #98cff9;
}

.shop_by_health {
  background-color: #fecea8;
}

.shop_by_category {
  background-color: #6950ba;
}

.shop_by_pet_card,
.shop_by_health,
.shop_by_category {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  max-height: 290px;
}

.shop_by_pet_card h4,
.shop_by_health h4,
.shop_by_category h4 {
  font-weight: 700;
  font-size: 24px;
  margin: 2rem 2rem;
  z-index: 1;
}

.shop_by_category h4 {
  color: #ffffff;
}

.shop_by_pet_card h4::after,
.shop_by_health h4::after,
.shop_by_category h4::after {
  content: "";
  display: block;
  height: 1px;
  width: 75px;
  background-color: #201838;
  margin-top: 18px;
}

.shop_by_category h4::after {
  background-color: #ffffff;
}

.shop_by_pet_card img,
.shop_by_health img,
.shop_by_category img {
  width: 100%;
  position: sticky;
  bottom: 0;
}

.services_card {
  border: 1px solid #e4e3e7;
  border-radius: 16px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
}

.services_card:hover {
  cursor: pointer;
  border-color: #6950ba70;
  box-shadow: 0px 0px 20px #635d7410;
}

.services_card span {
  font-weight: 500;
}

.category_services_card {
}

.category_services_card span {
  font-weight: 500;
}

.services_section .category_services_card:first-child {
  background-color: #feffd3;
  border-top-left-radius: 16px;
}

.services_section .category_services_card:nth-child(2) {
  background-color: #f5d9ff;
  border-top-right-radius: 16px;
}

.services_section .category_services_card:nth-child(3) {
  background-color: #ffdfc2;
  border-bottom-left-radius: 16px;
}

.services_section .category_services_card:last-child {
  background-color: #edffdc;
  border-bottom-right-radius: 16px;
}

/* services end */

/* offer section start */
.offer_section {
  background: linear-gradient(135deg, #6950ba, #c3b9e3);
  padding-top: 78px;
  padding-bottom: 78px;
  position: relative;
}

.offer_section::before,
.offer_section::after {
  content: "";
  height: 100%;
  width: 100%;
  max-width: 650px;
  max-height: 158px;
  position: absolute;
  pointer-events: none;
  filter: brightness(2);
}

.offer_section::before {
  background: url(./assets/images/offer_bg1.png) no-repeat;
  top: 0;
  left: 0;
}

.offer_section::after {
  background: url(./assets/images/offer_bg2.png) no-repeat;
  bottom: 0;
  right: 0;
}

.offer_section h3 {
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  margin: auto 0;
}

.offer_section .offer_timer_badge {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  color: #6950ba;
  border-radius: 6px;
  padding: 9px 12px;
}
.card.product_shop_card {
  cursor: pointer;
  background-color: #ffffff;
  border: none;
}
.product_shop_card .shop_card_img {
  border-radius: 20px;
  overflow: hidden;
}
.product_shop_card .shop_card_img img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
}
.product_shop_card > h5 {
  color: #201838;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0 5px;
  text-align: center;
  padding: 0 5rem;
}
.card.product_offer_card {
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
}
.card.product_offer_card > div.row {
  margin-top: auto;
}

.product_offer_card .product_offer_img {
  border-radius: 8px;
  overflow: hidden;
}

.product_offer_card .product_offer_img img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 6/4;
}

.product_offer_card > h5 {
  color: #201838;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 5px;
}

.product_offer_card > p {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.product_offer_card > span {
  color: #635d74;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin: 10px 0 15px;
}

.product_offer_card > span::before {
  content: "";
  background: url(./assets/svg/star.svg) no-repeat;
  display: flex;
  width: 26px;
  height: 20px;
  margin-right: 5px;
}

.product_offer_card .product_offer_price {
  color: #201838;
  font-weight: 700;
  margin: auto 0;
}

.product_offer_card button {
  background: #ffffff;
  border: 1px solid #e4e3e7;
  border-radius: 8px;
  color: #6950ba;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 22px;
  transition: all 0.2s ease;
}

.product_offer_card button:hover {
  background: #6950ba;
  color: #ffffff;
}

/* offer section end */

/* report and register start */
.report_register_section {
  overflow-x: hidden;
}

.report_card {
  background: url(./assets/images/report_card.png) no-repeat;
  background-size: cover;
  padding: 32px;
}

.register_card {
  background: url(./assets/images/register_card.png) no-repeat;
  background-size: cover;
  padding: 32px;
}

.report_card_image > div,
.register_card_image > div {
  margin: auto;
}

.report_card img,
.register_card img {
  max-width: 325px;
  width: 100%;
}

.report_card .report_card_content,
.register_card .register_card_content {
  padding-left: 3rem;
}

.report_card h2 {
  font-size: 32px;
  font-weight: 700;
  color: #162b3a;
}

.register_card h2 {
  font-size: 32px;
  font-weight: 700;
  color: #4e2517;
}

.report_card p {
  color: #201838;
}

.register_card p {
  color: #4e2517;
}

.report_card button {
  font-size: 14px;
  font-weight: 700;
  color: #368acb;
  background: #ffffff;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
}

.register_card button {
  font-size: 14px;
  font-weight: 700;
  color: #ac5f43;
  background: #ffffff;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
}

/* report and register end */

/* blog posts and explore video start */
.blog_post_section {
  background: linear-gradient(to right, #c3b9e30f, #c3b9e30f);
  padding: 90px 0 86px;
}

.explore_video_section {
  padding: 90px 0 86px;
}

.blog_post_section h3,
.explore_video_section h3 {
  font-size: 30px;
  font-weight: 700;
  color: #201838;
  margin: auto 0;
}

.blog_post_section h3 + p,
.explore_video_section h3 + p {
  font-size: 14px;
  color: #635d74;
  margin: auto 0;
}
.product_list_heading + .view_all_btn,
.blog_post_section .view_all_btn,
.explore_video_section .view_all_btn,
.product_detail_review_viewall .view_all_btn {
  background-color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 6px;
  padding: 9px 12px;
  border: none;
  outline: none;
  transition: all 0.2s ease;
}

.product_list_heading + .view_all_btn:hover,
.blog_post_section .view_all_btn:hover,
.explore_video_section .view_all_btn:hover,
.product_detail_review_viewall .view_all_btn:hover {
  background-color: #624baf;
  border: none;
  outline: none;
}
.card.blog_post_card,
.card.explore_video_card {
  background: transparent;
  border: none;
  border-radius: 12px;
}

.blog_post_card .blog_post_img,
.explore_video_card .explore_video_img {
  border-radius: 8px;
  overflow: hidden;
}

.blog_post_card .blog_post_img img,
.explore_video_card .explore_video_img img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/10;
}
/* 
.blog_post_card > h5,
.explore_video_card > h5 { */
.blog_post_card > h5,
.explore_video_card > h5 {
  color: #201838;
  font-size: 20px;
  font-weight: 600;
  margin: 18px 0 10px;
}

.blog_post_card > p {
  color: #635d74;
  font-size: 14px;
  margin: 0;
}

.blog_post_card > span,
.explore_video_card > a,
.explore_video_card > span {
  color: #6950ba;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  letter-spacing: -0.2px;
}
.video_action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.video_action .like {
  cursor: pointer;
}
.video_action span,
.video_action a {
  color: #6950ba;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  letter-spacing: -0.2px;
  margin-right: 25px;
  outline: none;
}
.video_action span img,
.video_action a img {
  margin-right: 5px;
}
.explore_video_card > span {
  margin-top: 10px;
}
.reads_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
div.author_info {
  display: flex;
  align-items: center;
  gap: 5px;
}
div.author_info > img {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
}
div.author_info > p {
  color: #1c1c21;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

/* blog posts end */
/* home page end */
/* blog detail page start */
.blog_detail > img {
  aspect-ratio: 16/7;
  border-radius: 12px;
  width: 100%;
  object-fit: cover;
}
.blog_detail > h5 {
  color: #1c1c21;
  font-family: "Manrope", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.5px;
  margin: 25px 0 0;
}
.blog_detail > hr {
  border-top: 1px solid #e4e3e7;
  opacity: 1;
}
.blog_detail > p {
  color: #1c1c21;
  font-family: "Manrope", sans-serif;
  line-height: 28px;
  letter-spacing: 0.5px;
}
/* blog detail page end */

/* login page start */
.login_section {
  margin: 85px auto;
}

.login_heading {
  color: #6950ba;
  font-size: 40px;
  font-weight: 700;
}

.login_desc {
  color: #1b202e;
  font-size: 16px;
  max-width: 600px;
}

.login_desc + hr {
  margin: 35px 0;
}

.social_auth_login {
  list-style: none;
  padding-left: 0;
}

.social_auth_login li {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 20px;
  cursor: pointer;
}

.social_auth_login li span {
  margin: auto 0;
}

.social_auth_login li:hover {
  border-color: #cbcacc;
}

.social_auth_login li + li {
  margin-top: 20px;
}

.auth_option_divider {
  width: 1px;
  background: #e4e3e7;
  position: relative;
  margin: 0 auto;
}

.auth_option_divider::after {
  content: "or";
  position: absolute;
  font-size: 20px;
  color: #635d74;
  background: #fff;
  top: 50%;
  left: 50%;
  width: 30px;
  text-align: center;
  height: 30px;
  transform: translate(-50%, -50%);
  font-weight: 500;
}

.login_mobile_heading {
  font-weight: 600;
  color: #201838;
}

.login_mobile_input {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.login_mobile_input:hover {
  border-color: #cbcacc;
}

.login_mobile_input:focus {
  border-color: #6950ba;
}

.login_mobile_input input {
  color: #201838;
  font-weight: 600;
}

.login_mobile_input input:placeholder-shown {
  font-weight: 500;
  font-size: 14px;
  color: #bcbac3;
}

.login_mobile_input input:first-child {
  width: 70px;
  padding: 15px 15px 15px 20px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  position: relative;
  border: none;
  outline: none;
}

.login_mobile_input span {
  height: 20px;
  width: 1px;
  background-color: #e4e3e7;
}

.login_mobile_input input:last-child {
  width: 100%;
  padding: 15px 20px 15px 15px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
  outline: none;
}

.login_otp_input {
  border: 1px solid #e4e3e7;
  padding: 12px 20px;
  border-radius: 12px;
  width: 100%;
  outline: none;
  font-size: 22px; /* Increase font size to make dots larger */
  height: 56px;
  text-align: center;
  color: #635d74;
  /* -webkit-text-security: disc; Ensures password dots */
  letter-spacing: 5px; /* Add spacing between dots for better visibility */
  box-sizing: border-box; /* Prevents padding from affecting dimensions */
}

.login_otp_input::placeholder {
  font-size: 18px; /* Match input text size */
  letter-spacing: 5px; /* Adjust for uniformity */
  color: #d9d9d9;
  text-align: center; /* Ensure placeholder is centered */
  line-height: 56px; /* Match input height for vertical alignment */
}

.login_otp_input:hover {
  border-color: #cbcacc;
}

.login_input_instruction {
  font-size: 14px;
  color: #635d74;
  margin-top: 12px;
}

.login_input_instruction span {
  font-weight: 600;
  color: #6950ba;
  cursor: pointer;
}

.login_input_instruction span:hover {
  color: #403469;
}

/* login page end */

/* account page start */
/* my-profile start */
.account_page,
.blogs_page,
.videos_page,
.terms_policy,
.about_section {
  padding: 60px 0 150px;
}

.profile_name_card {
  background-color: #6950ba;
  border-radius: 12px;
  padding: 20px 22px;
  color: #ffffff;
}

.profile_name_card > img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.profile_name_card p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  /* color: #ffffff; */
}

.profile_name_card span {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  letter-spacing: 0.3px;
  /* color: #ffffff; */
}

.account_page .nav-pills,
.account_page .accordion {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  overflow: hidden;
  --bs-accordion-border-width: 0;
}

.account_page .nav-pills.my_profile,
.account_page .accordion.my_profile {
  margin: 1rem 0 0;
}

.account_page .nav-pills .nav-item + .nav-item,
.account_page .accordion .accordion-item + .accordion-item {
  border-top: 1px solid #e4e3e7;
  display: contents;
}

.account_page .nav-pills .nav-link,
.account_page .accordion .accordion-button {
  padding: 18px 20px;
  display: flex;
  align-items: center;
  border-radius: 0;
}

.account_page .my_profile.nav-pills .nav-link.active,
.account_page .my_profile.nav-pills .show > .nav-link,
.account_page .my_profile.accordion .accordion-button:not(.collapsed) {
  background-color: #b7e0ff;
}

.account_page .my_consultation.nav-pills .nav-link.active,
.account_page .my_consultation.nav-pills .show > .nav-link,
.account_page .my_consultation.accordion .accordion-button:not(.collapsed) {
  background-color: #6950ba;
}

.account_page .my_consultation.nav-pills .nav-link.active *,
.account_page .my_consultation.nav-pills .show > .nav-link *,
.account_page .my_consultation.accordion .accordion-button:not(.collapsed) * {
  color: #ffffff;
}

.account_page .my_consultation.nav-pills .nav-link.active svg,
.account_page .my_consultation.nav-pills .show > .nav-link svg,
.account_page .my_consultation.accordion .accordion-button:not(.collapsed)::after {
  filter: brightness(50);
}

.account_page .nav-pills .nav-link span,
.account_page .accordion .accordion-button span {
  margin: 0 auto 0 15px;
  color: #201838;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.account_page .accordion .accordion-button p {
  margin: 0 0 0 15px;
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  padding-right: 1rem;
}
.pet_registration_petprofile .accordion-button::after {
  margin-left: 0;
}

.account_page .my_consultation.nav-pills .nav-link span,
.account_page .my_consultation.accordion .accordion-button span {
  margin: 0 auto 0 0;
}

.account_page .tab-content {
  height: 100%;
}

.account_page .tab-content .tab-pane {
  height: 100%;
}

.tab_content_box {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  overflow: hidden;
  height: 100%;
}

.tab_content_box .tab_content_heading {
  background-color: #f3f5f8;
  padding: 25px 18px 18px;
}

.tab_content_box h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.tab_content_heading button {
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  border: none;
  margin-left: auto;
}

.tab_content_box button:hover {
  color: #403469;
}

.tab_content_box .tab_content {
  padding: 8px 18px;
}

.tab_content + .tab_content {
  border-top: 1px solid #e4e3e7;
}

.account_page .tab_content_box .form-label {
  font-size: 14px;
  font-weight: 500;
}

.account_page .tab_content_box .form-label::after {
  content: "*";
  color: #e03616;
}

.account_page .input_verify_otp .form-label::after {
  content: none;
}

.account_page .input_verify_otp .form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account_page .input_verify_otp .form-label button {
  font-size: 14px;
  font-weight: 600;
  color: #6950ba;
  background: none;
  outline: none;
  border: none;
}

.account_page .input_verify_otp .form-label button:hover {
  color: #403469;
}

.account_page .input_verify_otp .form-label span {
  font-size: 12px;
  color: #201838;
}

.account_page .tab_content_box .input_verify_otp {
  position: relative;
}

.account_page .tab_content_box .form-control,
.add_new_payment_card_form .form-control,
input.pac-target-input {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #201838;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  background: #fff;
  padding: 17px 16px;
}

.account_page .tab_content_box .form-control::placeholder,
.add_new_payment_card_form .form-control::placeholder,
input.pac-target-input::placeholder {
  color: #8a8792;
  font-weight: 400;
}

.account_page .tab_content_box .form-control:hover,
.account_page .tab_content_box .form-control:focus,
.add_new_payment_card_form .form-control:hover,
.add_new_payment_card_form .form-control:focus,
input.pac-target-input:hover,
input.pac-target-input:focus {
  border-color: #cbcacc;
  box-shadow: none;
}

.account_page .tab_content_box .input_verify_otp .input_verified,
.account_page .tab_content_box .input_verify_otp .input_getotp {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  font-size: 14px;
}

.account_page .tab_content_box .input_verify_otp .input_verified {
  color: #2d9d61;
  pointer-events: none;
  font-weight: 500;
}

.account_page .tab_content_box .input_verify_otp .input_getotp {
  color: #6950ba;
  font-weight: 600;
  cursor: pointer;
}

.account_page .tab_content_box .input_verify_otp .input_getotp:hover {
  color: #403469;
}

.account_page .btn_primary {
  padding: 15px 2rem;
}

.account_page .manage_address_list,
.account_page .payment_mode_list,
.select_address_list {
  margin: 14px 0;
  padding: 0;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  list-style: none;
}

.upi_mode_list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.account_page .manage_address_list li {
  padding: 22px;
  display: flex;
}

.account_page .payment_mode_list li,
.select_address_list li,
.upi_mode_list li {
  position: relative;
}

.account_page .payment_mode_list li > input,
.select_address_list li > input,
.upi_mode_list li > input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.account_page .payment_mode_list li > input:checked + div .manage_address_action > div,
.select_address_list li > input:checked + div .manage_address_action > div,
.upi_mode_list li > input:checked + div .manage_address_action > div {
  border: 6px solid #6950ba;
}

.account_page .payment_mode_list li > div,
.select_address_list li > div {
  padding: 22px;
  display: flex;
  align-items: center;
}

.select_address_list li > div svg {
  margin: auto 0;
}

.upi_mode_list li > div {
  display: flex;
  align-items: center;
}

.account_page .payment_mode_list li > div > div:first-child {
  width: 52px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upi_mode_list li > div > div:first-child {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account_page .manage_address_list li + li,
.account_page .payment_mode_list li + li,
.select_address_list li + li {
  border-top: 1px solid #e4e3e7;
}

.account_page .manage_address_info,
.select_address_list .manage_address_info {
  margin-left: 12px;
}

.account_page .manage_address_info span,
.select_address_list .manage_address_info span {
  color: #201838;
  font-size: 16px;
  font-weight: 600;
}

.account_page .manage_address_info p {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  max-width: 300px;
  margin: 8px 0 0;
}

.select_address_list .manage_address_info p {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  max-width: auto;
  margin: 8px 0 0;
}

.account_page .manage_address_action {
  margin: 0 0 auto auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.select_address_list .manage_address_action {
  margin: auto 0 auto auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.account_page .manage_address_action .edit_pen_button {
  font-size: 14px;
  font-weight: 500;
  color: #6950ba;
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  background: #fff;
  padding: 8px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.account_page .manage_address_action .edit_pen_button:hover {
  border-color: #6950ba60;
}

.remove_bin_button {
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  background: #fff;
  padding: 8px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #e03616;
}

button.remove_bin_button:hover {
  border-color: #e0361650;
  color: #e03616;
}

.account_page .manage_address_action .form-check-input {
  border-color: #8a8792;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.account_page .manage_address_action .form-check-input:checked {
  background-color: #6950ba;
  border-color: #6950ba;
}

.account_page .manage_address_action .form-check-input:focus {
  box-shadow: none;
}

.account_page .manage_address_action .form-control {
  max-width: 70px;
  border-radius: 9px;
  text-align: center;
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
  margin-right: 16px;
  z-index: 1;
}

.account_page .manage_address_action .form-control::placeholder {
  color: #8a8792;
  font-weight: 400;
}

.account_page .manage_address_action .form-control:hover,
.account_page .manage_address_action .form-control:focus {
  box-shadow: none;
  border-color: #cbcacc;
}

.account_page .manage_address_action > div,
.select_address_list .manage_address_action > div {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #8a8792;
}

.add_new_address_modal .modal-content {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  background: #f3f5f8;
}

.add_new_address_modal .modal-body {
  background: #ffffff;
}

.add_new_address_modal .modal-body + .modal-body {
  margin-top: 6px;
}

.add_new_address_modal .modal-header {
  border-bottom: none;
}

.add_new_address_modal .modal-header .h4 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
}

.add_new_address_modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.add_new_address_modal .new_address_current_location {
  border-radius: 7px;
  background: #f0edf8;
  border: 1px solid #f0edf8;
  padding: 1rem 20px;
  color: #6950ba;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}

.add_new_address_modal .new_address_current_location:hover {
  border-color: #6950ba50;
}

.add_new_address_modal .new_address_form_input .form-label {
  color: #8a8792;
  font-size: 14px;
  font-weight: 500;
}

.add_new_address_modal .new_address_form_input .form-label::after {
  content: "*";
  color: #e03616;
}

.add_new_address_modal .new_address_form_input .form-control {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  padding: 18px 16px;
  border-radius: 14px;
}

.add_new_address_modal .new_address_form_input .form-control::placeholder {
  color: #8a8792;
  font-weight: 400;
}

.add_new_address_modal .new_address_form_input .form-control:hover,
.add_new_address_modal .new_address_form_input .form-control:focus {
  border-color: #cbcacc;
  box-shadow: none;
}

.add_new_address_modal .new_address_save_type_heading {
  color: #8a8792;
  font-size: 12px;
  font-weight: 500;
}

.add_new_address_modal .new_address_save_type_heading::after {
  content: "*";
  color: #e03616;
}

.add_new_address_modal .new_address_save_type {
  border-radius: 22px;
  position: relative;
  margin: 0;
}

.add_new_address_modal .new_address_save_type input {
  position: absolute;
  inset: 0;
  opacity: 0;
  pointer-events: none;
}

.add_new_address_modal .new_address_save_type label {
  border-radius: 22px;
  padding: 10px 0;
  text-align: center;
  border: 1px solid #8a8792;
  color: #8a8792;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.add_new_address_modal .new_address_save_type .form-check-input:checked + label {
  color: #fff;
  border-color: #6950ba;
  background-color: #6950ba;
}

.add_new_payment_card_form {
  margin: 14px 0;
}

.add_new_payment_card_form .card_expirydate_input {
  position: relative;
}

.add_new_payment_card_form .card_expirydate_input label {
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  color: #201838;
  font-size: 14px;
  font-weight: 500;
}

.add_new_payment_card_form .card_expirydate_input input {
  text-align: end;
}

.add_new_payment_card_form .btn_primary:hover {
  color: #ffffff !important;
}

.add_new_payment_card_form p {
  color: #201838;
  font-size: 16px;
  font-weight: 600;
}

.notification_list {
  list-style: none;
}

.notification_list li {
  list-style: none;
  padding: 18px 20px;
}

.notification_list li + li {
  border-top: 1px solid #e4e3e7;
}

.notification_list li > div:first-child {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
}

.notification_list li > div:first-child img {
  margin: auto;
  object-fit: cover;
}

.notification_list li > div p {
  color: #201838;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.128px;
  margin-bottom: 4px;
}

.notification_list li > div p b {
  font-weight: 600;
}

.notification_list li > div span {
  color: #635d74;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.notification_list li > svg {
  margin-left: auto;
  width: 100%;
  max-width: 24px;
}

.reminder_card.card {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
}

.reminder_card .reminder_card_img {
  width: 70px;
  height: 70px;
  background: #f7e3e1;
  border-radius: 12px;
  display: flex;
  margin-right: 12px;
  overflow: hidden;
}

.reminder_card .reminder_card_img img {
  margin: auto;
  width: 100%;
}

.reminder_card .reminder_card_info p {
  margin-bottom: 5px;
  color: #201838;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
}

.reminder_card .reminder_card_info span {
  display: block;
  margin-top: 5px;
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
}

.reminder_card .set_reminder {
  margin: 12px 0;
}

.reminder_card .set_reminder p {
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  margin: 0 12px;
}

.refer_earn_heading {
  background: #f3f5f8;
}

.refer_earn_heading h5 {
  color: #201838;
  font-size: 22px;
  font-weight: 700;
}

.refer_earn_heading p {
  color: #635d74;
  font-size: 16px;
  font-weight: 400;
}

.refer_earn_heading p span {
  color: #6950ba;
  font-weight: 800;
}

.refer_earn_heading button {
  width: inherit;
}

.tab_content_box .refer_earn_heading button:hover {
  color: #ffffff;
}

.tab_content_box .refer_earn_process_heading {
  color: #201838;
  font-size: 14px;
  font-weight: 700;
  margin-top: 24px;
}

.tab_content_box .refer_earn_process {
  position: relative;
}

.tab_content_box .refer_earn_process::before {
  content: "";
  position: absolute;
  left: 22px;
  top: 15px;
  bottom: 15px;
  border-left: 2px dashed #c3b9e3;
}

.tab_content_box .refer_earn_process .refer_earn_step_img {
  min-width: 45px;
  min-height: 45px;
  display: flex;
  border-radius: 42px;
  background: #f1ecff;
  margin-right: 16px;
  z-index: 1;
}

.tab_content_box .refer_earn_process .refer_earn_step_img img {
  margin: auto;
}

.tab_content_box .refer_earn_process .refer_earn_step_info span {
  color: #201838;
  font-size: 14px;
  font-weight: 700;
}

.tab_content_box .refer_earn_process .refer_earn_step_info p {
  color: #635d74;
  font-size: 12px;
  font-weight: 400;
  margin: 4px 0 0;
}

/* my-profile end */

/* my-consultation start */
ul.consultation_list,
ul.myorders_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.consultation_list li,
ul.myorders_list li {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 22px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

ul.consultation_list li:hover,
ul.myorders_list li:hover {
  border-color: #6950ba70;
  box-shadow: 0px 0px 20px #635d7410;
}

ul.consultation_list li + li,
ul.myorders_list li + li {
  margin-top: 1rem;
}

.consultation_list .consultation_listitem_image {
  max-width: 56px;
  max-height: 56px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.consultation_list .consultation_listitem_image img {
  object-fit: cover;
  width: 100%;
}

.consultation_list .consultation_listitem_content h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.consultation_list .consultation_listitem_content span {
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.consultation_list .btn_primary_outline_box {
  padding: 10px 20px;
  border-radius: 7px;
  margin: auto 0 auto auto;
}

.consultation_list .status_badge {
  display: inline-flex;
  height: 37px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 7px;
  margin: auto 10px auto auto;
}

.consultation_list .status_badge.complete_badge {
  background: #e9fff3;
  color: #2d9d61;
}

.consultation_list .status_badge.cancel_badge {
  background: #fff4f2;
  color: #e03616;
}

/* my-consultation end */

/* my-orders start */
.myorders_list .order_id {
  color: #201838;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.03px;
}

.myorders_list .order_price > span {
  color: #6950ba;
  font-size: 21px;
  font-weight: 700;
  margin-left: auto;
}

.myorders_list .order_items {
  color: #635d74;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.02px;
}

.myorders_list .order_items > span > span {
  color: #201838;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.02px;
}

.myorders_list .order_date > span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02px;
  margin-left: auto;
}

.myorders_list .order_date > span > span {
  color: #201838;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.02px;
}

.myorders_list .order_date > span > span.delivered_tag {
  color: #2d9d61;
}

.myorders_list .order_date > span > span.cancel_tag {
  color: #e03616;
}

/* my-orders end */
/* my-order detail start */
.order_detail_shipping_detail {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 0 18px;
}

.order_detail_shipping_detail > p {
  color: #201838;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px dashed #c3b9e3;
  padding: 18px 0;
  margin: 0;
}

.order_detail_shipping_address {
  display: flex;
  align-items: start;
  padding: 15px 0;
}

.order_detail_shipping_address > div:first-child {
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #e4e3e7;
  margin-right: 12px;
}

.order_detail_shipping_address p {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.order_detail_shipping_address span {
  color: #635d74;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  max-width: 280px;
}

.order_detail_item_ordered_list p {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0;
}

.order_detail_item_ordered_list ul {
  padding: 0 18px;
  margin: 0;
  list-style: none;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
}

.order_detail_item_ordered_list .order_detail_item_ordered {
  padding: 18px 0;
  display: flex;
}

.order_detail_item_ordered_list .order_detail_item_ordered + .order_detail_item_ordered {
  border-top: 1px solid #e4e3e7;
}

.order_detail_item_ordered_list .order_detail_item_ordered > div:first-child {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  margin-right: 20px;
  aspect-ratio: 1;
}

.order_detail_item_ordered_list .order_detail_item_ordered img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.order_detail_item_ordered .order_detail_product_name p {
  color: #201838;
  font-weight: 700;
  letter-spacing: -0.03px;
  margin: 0;
}

.order_detail_item_ordered .order_detail_product_price span {
  color: #201838;
  font-size: 20px;
  font-weight: 700;
  margin-left: auto;
}

.order_detail_item_ordered .order_detail_product_desc span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02px;
}

.order_detail_item_ordered .order_detail_product_quantity div {
  display: inline;
  border-radius: 8px;
  border: 1px solid var(--Border, #e4e3e7);
  padding: 4px 12px;
}

.order_detail_item_ordered .order_detail_product_quantity span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02px;
}

.order_detail_item_ordered .order_detail_product_quantity span span {
  color: #6950ba;
  font-weight: 600;
}
.order_detail_item_ordered .rate_review_link_btn {
  order: 5;
}
.order_detail_item_ordered .rate_review_link_btn p {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  display: inline-flex;
  align-items: end;
  justify-content: end;
  gap: 10px;
  text-align: end;
  cursor: pointer;
}

.order_process_status {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 8px 20px;
  margin: 0 0 20px;
  list-style: none;
}
.order_process_status > p {
  margin-bottom: 4px;
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px dashed #c3b9e3;
  margin: 4px 0;
}
.order_process_status > span {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  margin: 12px 0;
  display: block;
}

.order_process_status li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
}

.order_process_status li:first-child::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background: #2d9d61;
  top: 50%;
  left: 11px;
}

.order_process_status li img {
  margin-right: 20px;
  z-index: 1;
}

.order_process_status li p {
  margin-bottom: 4px;
  color: #000;
  font-weight: 700;
}

.order_process_status li span {
  margin: 0;
  color: #635d74;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.02px;
}

.order_bill_detail {
  background-color: #f3f5f8;
  border-radius: 12px;
  overflow: hidden;
}

.order_bill_detail > p {
  background-color: #6950ba;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 24px 20px;
  margin: 0;
}

.order_bill_detail > div {
  padding: 20px;
}

.order_bill_detail ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.order_bill_detail ul li {
  color: #635d74;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.order_bill_detail ul li span {
  margin-left: auto;
  color: #201838;
  font-weight: 600;
}

.order_bill_detail > div p {
  color: #201838;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.03px;
  border-top: 1px solid #e4e3e7;
  margin: 20px 0 32px;
  padding-top: 24px;
  display: flex;
}

.order_bill_detail > div p span {
  color: #6950ba;
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
}

/* cancel order and rate review modal start */
.cancel_order_modal .modal-content,
.rate_review_modal .modal-content {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  background: #fff;
}

.cancel_order_modal .modal-body,
.rate_review_modal .modal-body {
  background: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
}

.cancel_order_modal .modal-header,
.rate_review_modal .modal-header {
  border-bottom: none;
}

.cancel_order_modal .modal-header .h4,
.rate_review_modal .modal-header .h4 {
  color: #201838;
  font-size: 16px;
  font-weight: 700;
}

.cancel_order_modal .modal-header .btn-close:focus,
.rate_review_modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.cancel_order_modal .form-label,
.rate_review_modal .form-label {
  color: #8a8792;
  font-size: 14px;
  font-weight: 400;
}

.cancel_order_modal .form-label::after,
.rate_review_modal .form-label::after {
  content: "*";
  color: #e03616;
}

.cancel_order_modal .form-control,
.rate_review_modal .form-control {
  color: #201838;
  font-size: 14px;
  padding: 18px 16px;
  border-radius: 12px;
  font-weight: 600;
}

.cancel_order_modal .form-control::placeholder,
.rate_review_modal .form-control::placeholder {
  color: #8a8792;
  font-weight: 400;
}

.cancel_order_modal .form-control:hover,
.cancel_order_modal .form-control:focus,
.rate_review_modal .form-control:hover,
.rate_review_modal .form-control:focus {
  border-color: #cbcacc;
  box-shadow: none;
}

.cancel_order_modal .modal-footer,
.rate_review_modal .modal-footer {
  border-top: none;
}

.rate_review_modal_product_info img {
  max-width: 55px;
  max-height: 55px;
  min-width: 55px;
  max-width: 55px;
  border-radius: 10px;
}
.rate_review_modal_product_info p {
  font-weight: 700;
  letter-spacing: 0.1px;
  margin: 10px 0 30px;
}
.rate_review_modal .rate_review_stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}

.rate_review_modal .rate_review_star,
.rate_review_modal .rate_review_star_checked {
  width: 100%;
  border-radius: 22px;
  padding: 8px 0;
  background: #8a8792;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rate_review_modal .rate_review_star_checked {
  background: #ffa052;
}

.rate_review_modal .rate_review_star svg {
  /* width: 20px;
  height: 20px; */
}

/* cancel order and rate review modal end */

/* my-order detail end */
/* account page end */

/* navtab label start */
.navtabs_w_label {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
}

.navtab_label {
  background-color: #f3f5f8;
  padding: 22px 20px;
}

.navtab_label h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

/* navtab label end */

/* pet registration start */
.account_page .nav-pills .nav-link img,
.account_page .accordion .accordion-button img {
  max-width: 48px;
  height: 48px;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.navtabs_w_label .nav-pills .nav-link div > p,
.navtabs_w_label .accordion .accordion-button div > p {
  margin: 0 0 0 15px;
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  padding-right: 1.5rem;
}

.navtabs_w_label .nav-pills,
.navtabs_w_label .nav-pills {
  margin: 0 !important;
  border: none;
  border-radius: 0;
}

.add_new_pet_heading {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  background: #f0edf8;
  padding: 22px 18px;
  margin: 0;
}

.select_pet_heading,
.select_pet_heading_w_require {
  color: #201838;
  font-weight: 600;
  letter-spacing: -0.4px;
  margin: 10px 0 1rem;
}

.select_pet_heading_w_require::after {
  content: "*";
  color: #e03616;
}

.select_pet_heading button,
.select_pet_heading_w_require + button {
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  border: none;
  margin-left: auto;
}

.add_new_pet_select_pet {
  border-radius: 16px;
  height: 100%;
  position: relative;
}

.add_new_pet_select_pet input {
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 16px !important;
  margin: 0;
  cursor: pointer;
}

.add_new_pet_select_pet span {
  text-align: center;
  color: #201838;
  font-size: 15px;
  font-weight: 500;
}

.add_new_pet_select_pet > div {
  border-radius: 16px;
  border: 1px solid #e4e3e7;
  height: 100%;
  gap: 1rem;
}

.add_new_pet_select_pet input:checked + div {
  background-color: #b7e0ff;
}

.pet_upload_picture_input {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.pet_upload_picture_input > div {
  width: 82px;
  height: 82px;
  border: 2px solid #e4e3e7;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
}

.pet_upload_picture_input > div img {
  margin: auto;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.pet_upload_picture_input > span {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
}

.pet_upload_picture_input > span:hover {
  color: #403469;
}

.pet_upload_picture_input > span input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0 !important;
}

.status_badge_pending,
.status_badge_completed {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
}

.status_badge_pending {
  color: #eaa250;
  background: #fff4e8;
}

.status_badge_completed {
  color: #2d9d61;
  background: #e3fff0;
}

.navtabs_w_label .status_badge_completed + svg {
  min-width: 24px;
}

.add_new_pet .tab_content + .tab_content {
  border-top: 5px solid #f3f5f8;
}

/* .add_new_pet .tab_content:nth-child(3) {
  border-top: 5px solid #F3F5F8;
  border-bottom: 5px solid #F3F5F8;
} */

.rabies_vaccine_certificate_input {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #201838;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  background: #fff;
  padding: 6px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

.rabies_vaccine_certificate_input div {
  background: #b7e0ff;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 10px;
  border-radius: 6px;
}

.rabies_vaccine_certificate_input input.form-control {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.pet_registration_petprofile .add_new_pet_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 18px;
  margin-bottom: 20px;
}

.pet_registration_petprofile .add_new_pet_heading h6 {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0;
  color: #201838;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
}

.pet_registration_petprofile .add_new_pet_heading h6 svg:hover {
  opacity: 0.8;
  cursor: pointer;
}

.pet_registration_petprofile .tab_content_box .tab_content_heading {
  background-color: #b7e0ff;
}

.pet_registration_petprofile .tab_content_box .tab_content:nth-child(3) {
  border-top: 5px solid #f3f5f8;
  border-bottom: 5px solid #f3f5f8;
}

.pet_registration_petprofile .tab_content_box .pet_keyinfo {
  display: flex;
  gap: 15px;
}

.pet_registration_petprofile .tab_content_box .pet_keyinfo h6 {
  font-size: 20px;
}

.pet_registration_petprofile .tab_content_box .pet_keyinfo p {
  margin: 8px 0 0;
  width: 100%;
  word-break: break-all;
}

.pet_registration_petprofile .tab_content_box .pet_keyinfo img {
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
}

.pet_registration_petprofile .tab_content_box .pet_extrainfo > div > div {
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  gap: 8px;
  justify-content: end;
  align-items: center;
  /* width: 100%; */
}

.pet_registration_petprofile .tab_content_box .pet_extrainfo span {
  color: #635d74;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.pet_registration_petprofile .tab_content_box .vet_info span:first-child {
  color: #635d74;
  font-size: 16px;
  font-weight: 500;
}

.pet_registration_petprofile .tab_content_box .vet_info span:last-child {
  color: #201838;
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
}

.pet_registration_petprofile .tab_content_box .certificate_section p {
  color: #8a8792;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.pet_registration_petprofile .tab_content_box .certificate_section p::after {
  content: "*";
  color: #e03616;
}

.pet_registration_petprofile .tab_content_box .certificate_section img {
  aspect-ratio: 4/3;
  width: 100%;
  object-fit: fill;
  margin: 15px 0 10px;
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  padding: 4px;
}

/* pet registration end */
/* consultation_type start */
.consultation_type .nav-pills .nav-link span,
.consultation_type .accordion .accordion-button span {
  font-weight: 700;
}

.consultation_type .nav-pills .nav-link svg {
  min-width: 24px;
}

.time_slots_wrapper {
  max-height: 385px;
  overflow-y: scroll;
}

.timing_select_radio_btn {
  border-radius: 22px;
  position: relative;
  margin: 0;
}

.timing_select_radio_btn input {
  position: absolute;
  inset: 0;
  opacity: 0;
  pointer-events: none;
}

.timing_select_radio_btn label {
  border-radius: 8px;
  padding: 10px 0;
  text-align: center;
  border: 1px solid #e4e3e7;
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #fff;
}

.timing_select_radio_btn .form-check-input:checked + label {
  color: #403469;
  border-color: #403469;
}

/* filter page start */
.filter_page {
  padding: 60px 0 150px;
}

.filter_page_left_side_heading {
  padding-bottom: 20px;
  width: 100%;
}
.filter_page_left_side_heading h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
}
.filter_page_left_side_heading button {
  color: #e03616;
  font-weight: 700;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
}

.filter_page_right_side_heading {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
  width: 100%;
  margin-bottom: 4px;
  border-bottom: 1px solid #e4e3e7;
}

.filter_inputs .accordion-item {
  background: #f3f5f8;
  border-radius: 12px !important;
  border: none;
}

.filter_inputs .accordion-item + .accordion-item {
  margin-top: 15px;
}

.filter_inputs .accordion-item .accordion-button {
  background: #f3f5f8;
  border-radius: 12px !important;
  box-shadow: none !important;
  padding: 20px 18px 18px;
}

.filter_inputs .accordion-item .accordion-button::after {
  width: 15px;
  height: 15px;
  background-size: 15px;
}

.filter_inputs .accordion-item .accordion-button > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filter_inputs .accordion-item .accordion-button span {
  margin-right: 12px;
  color: #6950ba;
  font-size: 14px;
  font-weight: 500;
}

.filter_inputs h6 {
  color: #201838;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.filter_inputs .filter_by_date_time .accordion-button::after {
  margin: 4px 0 auto auto;
}

.filter_inputs .filter_by_date_time .accordion-button > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.filter_inputs .filter_by_date_time .accordion-button > div > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter_inputs .filter_by_date_time .accordion-button p {
  color: #6950ba;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.filter_inputs .filter_by_date_time .accordion-button span {
  background-color: #bcbac3;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0;
}

.filter_inputs .filter_tool_box {
  padding: 20px 18px 18px;
}

.filter_by_rate_star {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 24px;
}

.multi_range_slider {
  position: relative;
  margin: 30px 0 70px;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 3px;
}

.slider__track {
  background-color: #ccc;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #201838;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #201838;
  font-size: 12px;
  font-weight: 500;
  margin-top: 25px;
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: -0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #201838;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 11px;
  width: 11px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.filter_by_rate_star .filter_by_star,
.filter_by_rate_star .filter_by_star_checked {
  border-radius: 50%;
  padding: 2px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.doctor_list_doctor_card {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
}

.doctor_list_doctor_card .doctor_info {
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 55px;
}

.doctor_list_doctor_card .doctor_info > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.doctor_list_doctor_card .doctor_info h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
}

.doctor_list_doctor_card .doctor_info > div > div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.doctor_list_doctor_card .doctor_info span {
  color: #201838;
  font-size: 12px;
  font-weight: 600;
}

.doctor_list_doctor_card .doctor_info > img {
  aspect-ratio: 1/1;
  min-width: 55px;
  width: 100%;
  max-width: 55px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.doctor_list_doctor_card .doctor_extra_info span {
  color: #635d74;
  font-size: 12px;
}

.doctor_list_doctor_card .doctor_extra_info p {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.doctor_list_doctor_card .doctor_extra_info > div:nth-child(3) p {
  color: #6950ba;
}

.doctor_list_doctor_card .doctor_specialize {
  border-top: 2px dashed #c3b9e3;
  margin-top: 15px;
  padding-top: 15px;
}

/* filter page end */
/* instant consultation start */
.consultaion_booking_card {
  border-radius: 12px;
  background: #f3f5f8;
  padding: 0 16px;
}

.consultaion_booking_card h6 {
  color: #6950ba;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.consultaion_booking_card span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
}

.consultaion_booking_card p {
  color: #635d74;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

/* instant consultation end */
/* consultation_type end */
.box_container {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 20px;
  margin-bottom: 20px;
}

/* doctor detail page start */
.doctor_details_page,
.payment_page {
  padding: 50px 0 80px;
}

.about_veterian_box h6,
.payment_method_box h6,
.payment_details_box h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dashed #c3b9e350;
}

.about_veterian_box span,
.payment_method_box > span {
  color: #201838;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.about_veterian_box ul {
  padding-left: 1rem;
  margin-bottom: 40px;
}

.about_veterian_box ul li {
  color: #635d74;
  font-size: 14px;
  margin-bottom: 10px;
}

.about_veterian_box p {
  color: #635d74;
  font-size: 14px;
}

.revews_box h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 3px dashed #c3b9e350;
}

.revews_box ul {
  list-style: none;
  padding: 0;
}

.revews_box ul li + li {
  border-top: 1px solid #e4e3e7;
  padding-top: 20px;
  margin-top: 15px;
}

.revews_box .visited_for {
  color: #201838;
  font-weight: 600;
  display: block;
}

.revews_box .visited_at {
  color: #635d74;
  font-size: 14px;
  display: block;
  margin: 8px 0;
}

.revews_box p {
  color: #635d74;
  font-size: 14px;
}

.revews_box li > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.revews_box img {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.revews_box .review_from {
  color: #201838;
  font-weight: 600;
  display: block;
}

.revews_box .breed_owner {
  color: #635d74;
  font-size: 12px;
  display: block;
}

.revews_box .view_all_btn {
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.revews_box .view_all_btn:hover {
  color: #403469;
}

.consultation_timing_box h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 3px dashed #c3b9e350;
}

.consultation_timing_box p {
  color: #201838;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.consultation_timing_box span {
  color: #6950ba;
  font-size: 14px;
  font-weight: 500;
}

.doctor_info_card .doctor_info_card_photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.doctor_info_card .doctor_info_card_name h6,
.appointment_details_card .doctor_info_card_name h6 {
  color: #201838;
  font-size: 26px;
  font-weight: 700;
}

.doctor_info_card .doctor_info_card_name div,
.appointment_details_card .doctor_info_card_name div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.appointment_details_card .doctor_info_card_name p {
  color: #201838;
  font-weight: 600;
  margin: 0;
}

.doctor_info_card .doctor_info_card_name span {
  color: #201838;
  font-size: 12px;
  font-weight: 600;
}

.doctor_info_card .doctor_info_card_profession {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  margin: 18px 0;
  border-top: 3px dashed #c3b9e350;
  border-bottom: 3px dashed #c3b9e350;
}

.doctor_info_card .doctor_info_card_profession span {
  color: #635d74;
  font-size: 12px;
}

.doctor_info_card .doctor_info_card_profession p {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.doctor_info_card .doctor_info_card_pet_category > div > p {
  color: #635d74;
  font-size: 14px;
}

.doctor_info_card .doctor_info_card_pet_category > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.doctor_info_card .doctor_info_card_pet_category > div > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #e4e3e7;
  border-radius: 8px;
}

.doctor_info_card .doctor_info_card_pet_category > div > div img {
  min-height: 34px;
  max-height: 34px;
  min-width: 34px;
  max-width: 34px;
  margin: 12px 0;
}

.doctor_info_card .doctor_info_card_pet_category > div > div p {
  color: #201838;
  font-size: 12px;
  font-weight: 500;
}

.schedule_appointment_card h6,
.appointment_details_card > h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 12px 12px 0px 0px;
  padding: 24px 20px;
  background: #6950ba;
  margin: 0;
}

.schedule_appointment_card > div,
.appointment_details_card > div {
  border-radius: 0 0 12px 12px;
  padding: 20px;
  background: #f3f5f8;
}

.appointment_details_card > .doctor_info_card_name {
  border-radius: 0;
  padding: 20px;
  border-bottom: 2px dashed #c3b9e380;
}

.schedule_appointment_card .fees_box,
.appointment_details_card .fees_box {
  background-color: #fff;
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  justify-content: space-between;
}

.schedule_appointment_card .fees_box p,
.appointment_details_card .fees_box p {
  color: #6950ba;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.schedule_appointment_card .fees_box p span,
.appointment_details_card .fees_box p span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
}

.schedule_appointment_card .fees_box > span,
.appointment_details_card .fees_box > span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.schedule_appointment_card .select_pet_heading_w_require {
  margin: 0;
}

.schedule_appointment_card .timing_select_radio_btn .form-check-input:checked + label {
  color: #201838;
  border-color: #e4e3e7;
  background-color: #b7e0ff;
}

/* doctor detail page end */
/* payment page start */
.payment_method_box hr {
  border-top: 1px solid #e4e3e7;
  opacity: 1;
}

.enter_new_coupon_card > div {
  display: flex;
  align-items: center;
  position: relative;
}

.enter_new_coupon_card input {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #201838;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding-right: 100px;
  padding: 16px;
  text-transform: uppercase;
}

.enter_new_coupon_card input::placeholder {
  color: #8a8792;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 400;
  text-transform: none;
}

.enter_new_coupon_card input:hover,
.enter_new_coupon_card input:focus {
  box-shadow: none;
  border-color: #cbcacc;
}

.enter_new_coupon_card button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  background: none;
  border: none;
}

.enter_new_coupon_card button:hover {
  color: #403469;
}

.apply_coupon_card > div {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.apply_coupon_card > div > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 42px;
  max-height: 42px;
  width: 100%;
}

.apply_coupon_card > div > div img {
  width: 100%;
  object-fit: contain;
}

.apply_coupon_card > div span {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.apply_coupon_card > div p {
  margin: 4px 0 0;
  color: #635d74;
  font-size: 12px;
  font-weight: 500;
}

.apply_coupon_card > div button {
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  background: none;
  border: none;
  margin-left: auto;
}

.apply_coupon_card > div button:hover {
  color: #403469;
}

/* payment page end */
/* appointment details component start */
.appointment_info {
  list-style: none;
  gap: 14px;
  display: flex;
  flex-direction: column;
}

.appointment_info li {
  display: flex;
  align-items: center;
  gap: 12px;
}

.appointment_info li > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  padding: 9px;
  height: 40px;
  width: 40px;
}

.appointment_info li img {
  width: 100%;
  object-fit: cover;
}

.appointment_info li p {
  color: #201838;
  font-weight: 600;
  margin: 0;
}

/* appointment details component end */
/* payment details component end */
.payment_details_box .accordion-item {
  border: none;
}

.payment_details_box .accordion-button {
  border: none;
  border-radius: 0 !important;
  padding: 0;
  margin-bottom: 15px;
  color: #201838;
  --bs-accordion-btn-icon-width: 15px;
  font-weight: 500;
  box-shadow: none !important;
  border-color: transparent !important;
}

.payment_details_box .accordion-button:not(.collapsed),
.payment_details_box .accordion-button:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: transparent;
}

.payment_details_box .accordion-button > div {
  width: 40px;
  height: 40px;
  border: 1px solid #e4e3e7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.payment_details_box .accordion-body {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 15px;
}

.payment_details_box .accordion-body > span {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.payment_details_box .accordion-body > p {
  margin: 0;
  color: #201838;
  font-size: 12px;
  line-height: 20px;
}

/* payment details component end */

.treatment_info_card {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
}

.treatment_info_card li {
  padding: 18px;
}

.treatment_info_card li + li {
  border-top: 2px dashed #c3b9e350;
}

.treatment_pet_info,
.treatment_dr_info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.treatment_pet_info > div:first-child {
  border-radius: 12px;
  border: 1.5px solid #e4e3e7;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.treatment_dr_info > div:first-child {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.treatment_pet_info img {
  min-width: 33px;
  max-width: 33px;
  min-height: 33px;
  max-height: 33px;
  object-fit: cover;
}

.treatment_pet_info h4 {
  color: #201838;
  font-size: 26px;
  font-weight: 700;
}

.treatment_pet_info p {
  color: #201838;
  font-weight: 600;
  margin: 0;
}

.treatment_pet_info span {
  color: #6950ba;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  display: block;
}

.treatment_dr_info img {
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.treatment_dr_info p {
  color: #201838;
  font-weight: 500;
  margin: 0;
}

.treatment_dr_info p span {
  font-weight: 600;
}

/* e-health card start */
.health_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.health_box > p {
  margin: 0;
  color: #201838;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.health_box > p:after {
  content: "*";
  color: #e03616;
}

.health_box_action {
  display: flex;
  align-items: center;
  gap: 16px;
}

.yes_no_btn {
  border-radius: 8px;
  padding: 9px 18px;
  border: 1px solid #e4e3e7;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.yes_no_btn.active {
  border: 1px solid #b7e0ff;
  background-color: #b7e0ff;
}

.yes_no_btn span {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #635d74;
  cursor: pointer;
  display: flex;
}

.yes_no_btn.active span {
  border: 5px solid #6950ba;
}

.yes_no_btn p {
  margin: 0;
  color: #201838;
  font-size: 14px;
  font-weight: 600;
}

.yes_no_btn input:checked + div {
  background-color: #624baf;
}

.yes_no_btn .form-check-input:checked + div span {
  border: 5px solid #6950ba;
}

.health_box_expand {
  border-top: 2px dashed #c3b9e380;
  padding-top: 10px;
}

.health_box_expand .select_pet_heading,
.health_box_expand .select_pet_heading_w_require {
  color: #8a8792;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
}

.health_vaccine_heading {
  color: #201838;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin: 8px 0;
  border-bottom: 2px dashed #c3b9e380;
}

.tab_content_box .tab_content_footer {
  background-color: #f3f5f8;
  padding: 18px 18px;
}

.tab_content_footer .btn_primary_outline_box:hover {
  color: #fff;
}

.pet_disease_info {
  border-radius: 8px;
  border: 1px solid #e4e3e7;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
}

.pet_disease_info > p {
  margin: 0;
  color: #201838;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.pet_disease_info > span {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.pet_disease_info span {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.pet_disease_info span span {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
}

/* e-health card end */
/* pharmacy start */
.store_banner {
  background-color: #6950ba;
  padding: 22px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.store_banner h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  max-width: 260px;
  text-align: center;
}
.store_banner p {
  color: #e4e3e7;
  font-size: 12px;
  margin: 0;
  max-width: 260px;
  text-align: center;
}
.product_list_heading {
  color: #201838;
  font-size: 24px;
  font-weight: 700;
}
.pagination {
  display: flex;
  align-items: center;
  gap: 20px;
}
.pagination .arrow_prev,
.pagination .arrow_next {
  cursor: pointer;
}
.pagination .arrow_prev:hover path,
.pagination .arrow_next:hover path {
  fill: #777;
}
.pagination .pagi_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #8a8792;
  border-radius: 12px;
  cursor: pointer;
}
.pagination .pagi_btn.pagi_btn_active {
  color: #fff;
  background-color: #6950ba;
  border: 1px solid #6950ba;
}
.pagination > span {
  background-color: #8a8792;
  width: 4px;
  height: 4px;
}
/* pharmacy end */
/* product detail page start */
.product_detail_carousel .slide > div {
  aspect-ratio: 10/9;
  border-radius: 16px;
  overflow: hidden;
}
.product_detail_carousel .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product_detail_carousel .carousel .thumbs-wrapper {
  margin: 24px 0 0;
}
.product_detail_carousel .carousel .thumbs {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.product_detail_carousel.low_images .carousel .thumbs {
  justify-content: left;
}
.product_detail_carousel .carousel .thumb {
  position: unset;
  pointer-events: all;
  height: unset;
  border: none !important;
  margin: 0;
  padding: 0;
  width: 100% !important;
  aspect-ratio: 1;
  border-radius: 10px;
  overflow: hidden;
}
.product_detail_carousel.low_images .carousel .thumb {
  width: 80px !important;
}
.product_detail_carousel .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_detail_info > p {
  color: #201838;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.product_detail_info > p > span {
  color: #716d7c;
  font-size: 16px;
  text-decoration: line-through;
}
.product_detail_quantity {
  color: #635d74;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.032px;
}
.product_detail_star_rating {
  gap: 10px;
  color: #635d74;
  font-weight: 600;
}
.product_variant_select {
  position: relative !important;
  max-width: fit-content;
  margin: 16px 0 20px;
}
.product_variant_select::before {
  position: absolute !important;
  content: "Pack Size :";
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #635d74;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
}
.product_variant_select button {
  padding: 8px 16px 8px 94px;
  color: #6950ba;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.028px;
}
.product_variant_select button span {
  text-transform: uppercase;
  color: #6950ba;
}
.quantity_action_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  margin-right: 20px;
}
.quantity_action_btn span {
  color: #6950ba;
  font-weight: 700;
  letter-spacing: -0.032px;
}
.quantity_action_btn button {
  border: none;
  background: none;
}
.quantity_action_btn button:disabled svg path {
  stroke: #8a8792;
}
.product_delivery_terms {
  border-radius: 12px;
  border: 1px solid #e4e3e7;
  padding: 18px 22px;
}
.product_delivery_terms p {
  color: #201838;
  font-size: 14px;
  margin: 0;
}
.product_delivery_terms p span {
  color: #201838;
  font-size: 14px;
  font-weight: 600;
}
.product_delivery_terms > div {
  margin: 20px 0 16px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e4e3e7;
}

.product_offer_info {
  background-color: #f0edf8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 18px 22px;
}
.product_offer_info > div {
  gap: 14px;
  height: 20px;
}
.product_offer_info > div span {
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
}
.product_offer_info > div span img {
  height: 100%;
}
.product_offer_info > div h6 {
  color: #201838;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.product_offer_info > div p {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.028px;
  margin: 0;
}
.product_detail_desc {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  overflow: hidden;
}
.product_detail_desc h6 {
  background-color: #f3f5f8;
  color: #201838;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 22px 15px;
  margin: 0;
}
.product_detail_desc div {
  padding: 20px 22px;
}
.product_detail_desc span {
  color: #635d74;
  font-weight: 500;
  letter-spacing: -0.032px;
  display: block;
}
.product_detail_desc p {
  color: #8a8792;
  font-size: 14px;
  line-height: 151.9%;
  letter-spacing: -0.028px;
  margin: 0;
}
.product_detail_review_box {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  overflow: hidden;
}
.product_detail_review_heading {
  background-color: #f3f5f8;
  padding: 20px 22px;
}
.product_detail_review_heading h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
}
.product_detail_review_heading > div img {
  width: 40px;
}
.product_detail_review_heading > div p {
  color: #635d74;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.product_detail_review_heading > div p span {
  color: #201838;
  font-size: 28px;
  font-weight: 600;
}
.product_detail_review_box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.product_detail_review_box li {
}
.product_detail_review_box li + li {
  border-top: 5px solid #f3f5f8;
}
.product_detail_review_box li p {
  color: #8a8792;
  font-size: 14px;
  line-height: 140%; /* 20.636px */
  letter-spacing: -0.1px;
  margin-bottom: 0;
}
.product_detail_review_box .product_detail_review_profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.product_detail_review_box .product_detail_review_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product_detail_review_name p {
  color: #201838;
  font-weight: 600;
}
.product_detail_review_name .review_star_icon {
  width: 20px;
  height: 16px;
}
.product_detail_review_box .product_detail_review {
  padding: 20px 16px;
}
.product_detail_review_box .product_detail_review_viewall {
  background-color: #f3f5f8;
  padding: 20px 25px;
}
/* product detail page end */
/* cart start */
.attach_prescription {
  border-radius: 7px;
  background: #f0edf8;
  padding: 20px;
  margin: 6px 0 22px;
}
.attach_prescription label {
  color: #8a8792;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}
.attach_prescription label::after {
  color: #8a8792;
  font-size: 12px;
  font-weight: 500;
}
.attach_prescription p {
  color: #635d74;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.1px;
  margin: 15px 0 0;
}
.attach_prescription p span {
  color: #6950ba;
  font-weight: 600;
}
/* cart end */
/* animal abuse start */
.evidence_image_upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pet_abuse_img {
  position: relative;
  border-radius: 12px;
  background: var(--Secondary-Color, #b7e0ff);
  width: 70px;
  height: 70px;
  display: flex;
  overflow: hidden;
}
.pet_abuse_img img {
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.pet_abuse_img input {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  opacity: 0;
}
.nav_pill_steps {
  pointer-events: none !important;
}

.nav_pill_steps .nav-item .nav-link.active {
  color: #fff;
  background-color: #fff !important;
}
.nav_pill_steps .nav-item + .nav-item .nav-link {
  border-top: 1px solid #e4e3e7 !important;
}
.nav_pill_steps .nav-item .nav-link p {
  width: 24px;
  height: 24px;
  margin: 0;
  border-radius: 50%;
  color: #635d74;
  background-color: #e4e3e7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border: none;
}
.nav_pill_steps .nav-item .nav-link p.active {
  color: #fff;
  background-color: #6950ba;
}
.nav_pill_steps .nav-item .nav-link p.completed {
  background-color: #2d9d61;
}
.nav_pill_steps .nav-item .nav-link p.completed img {
  width: 16px;
}
.custom_progress {
}
.custom_progress > div {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.custom_progress > div::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #e4e3e7;
  top: 50%;
  transform: translateY(-50%);
}
.custom_progress > div::after {
  position: absolute;
}
.custom_progress > div > div {
  position: absolute;
  height: 3px;
  background-color: #6950ba;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.custom_progress_step {
  width: 32px;
  height: 32px;
  margin: 0;
  border-radius: 50%;
  color: #635d74;
  border: 4px solid #fff;
  background-color: #e4e3e7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  z-index: 2;
}
.custom_progress_step img {
  width: 16px;
}
.custom_progress_step.active {
  color: #fff;
  background-color: #6950ba;
}
.custom_progress_step.completed {
  color: #fff;
  background-color: #2d9d61;
}
.custom_progress h6 {
  font-weight: 600;
  font-size: 18px;
  margin: 1rem 0 3rem;
}
.progress-number {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  z-index: 2;
}
/* animal abuse end */
/* about page and contact page start */
.about_content,
.contact_content {
  border: 1px solid #e4e3e7;
  border-radius: 12px;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about_content h5 {
  color: #201838;
  font-size: 24px;
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 4px;
}
.contact_content h5 {
  color: #201838;
  font-size: 24px;
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 30px;
  padding: 0 11rem;
  text-align: center;
}
.about_content > span {
  color: #201838;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}
.about_content p,
.contact_content p {
  color: #635d74;
  line-height: 24px;
  text-align: center;
  padding: 0 50px;
  margin: 0;
}
.contact_content p > span {
  color: #6950ba;
  font-weight: 500;
}
/* about page and contact page end */
/* terms and policy start */
.terms_policy p {
  color: #201838;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 24px;
}
.terms_policy p > b {
  font-weight: 700;
}
/* terms and policy end */

/* pharmacy filters and sorting ui start */
.filter_column_heading {
  margin-bottom: 25px;
}
.filter_column_heading h5 {
  font-size: 24px;
  font-weight: 700;
}
.filter_column_heading button {
  color: #e03616;
  font-weight: 700;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
}
.filter_column_heading button:hover {
  color: #c22c10;
}
.filter_category_box {
  background-color: #f3f5f8;
  border: 1px solid #f3f5f8;
  border-radius: 12px;
  padding: 20px;
}
.filter_category_box > p {
  font-weight: 700;
  margin-bottom: 20px;
}
.filter_category_box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.filter_category_box li {
  position: relative;
  cursor: pointer;
}
.filter_category_box li + li {
  margin-top: 14px;
}
.filter_category_box li > input {
  position: absolute;
  inset: 0;
  opacity: 0;
}
.filter_category_box li > div {
  display: flex;
  align-items: center;
  gap: 26px;
}
.filter_category_box li > div > span {
  border: 1px solid #8a8792;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  border-radius: 50%;
  background-color: #fff;
}
.filter_category_box li > input:checked + div > span {
  background-color: #2d9d61;
}
.filter_category_box li > input:checked + div > span::after {
  content: "";
  position: relative;
  display: flex;
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.filter_category_box li > div > div {
  display: flex;
  align-items: center;
  word-break: break-word;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
}
.filter_category_box li > div > div > img {
  width: 32px;
  height: 32px;
}
.filter_category_box + .filter_category_box {
  margin-top: 20px;
}
.store_sort_heading {
  padding-bottom: 25px;
  border-bottom: 1px solid #e4e3e7;
  margin-bottom: 25px;
}
.store_sort_heading h6 {
  font-size: 18px;
  font-weight: 700;
}
/* pharmacy filters and sorting ui end */

/* connect start */
.connect_section {
  overflow: hidden;
}
.connect_header {
  background-color: #f5f5fd;
  border-radius: 12px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.doctor_detail {
  gap: 12px;
}
.doctor_detail > img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.doctor_detail p {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.doctor_detail span {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #201838;
  font-size: 12px;
  font-weight: 600;
}
.timer_badge {
  width: auto;
  border: 1px solid #ff000080;
  color: #17171f;
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  border-radius: 12px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.timer_badge::before {
  content: "";
  width: 16px;
  height: 16px;
  display: flex;
  background-color: #ff000080;
  border-radius: 50%;
}
.connect_header .waiting_text {
  color: #555;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.connect_header .btn_danger_outline_sm {
  border-radius: 9px;
  border: 1px solid #e03616;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e03616;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 14px;
  transition: 0.2s all ease;
}
.connect_header .btn_danger_outline_sm:hover {
  border-radius: 9px;
  border: 1px solid #e03616;
  background: #e03616;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 14px;
  transition: 0.2s all ease;
}
.connect_header .btn_primary_sm {
  border-radius: 9px;
  border: 1px solid #6950ba;
  background: #6950ba;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 14px;
  transition: 0.2s all ease;
}
.connect_header .btn_primary_sm:hover {
  border-radius: 9px;
  border: 1px solid #624baf;
  background: #624baf;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 14px;
  transition: 0.2s all ease;
}
.connect_main {
  height: calc(100vh - 146px);
  display: flex;
}
.stream_waiting {
  display: flex;
  gap: 25px;
  width: 100%;
}
.stream_waiting_ls {
  border-radius: 12px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0edf8;
}
.stream_waiting_rs {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden scroll;
  align-items: center;
  background-color: transparent;
}
.stream_waiting_ls p {
  font-size: 14px;
  max-width: 320px;
  text-align: center;
  margin: 1rem;
  font-weight: 500;
}
.stream_waiting_rs h4,
.stream_waiting_rs h4 {
  font-size: 18px;
  font-weight: 700;
}
.stream_waiting_rs .blog_post_card > h5,
.stream_waiting_rs .explore_video_card > h5 {
  font-size: 16px;
}
.stream_waiting_rs .blog_post_card .reads_count span,
.stream_waiting_rs .explore_video_card .reads_count span {
  font-size: 12px;
}
.reads_count a {
  font-size: 16px;
  text-decoration: none;
  color: #000;
}

.stream_ongoing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.stream_ongoing > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.stream_ongoing > div {
  width: 140px;
  height: 150px;
  position: absolute;
  border-radius: 12px;
  border: 4px solid #fff;
  overflow: hidden;
  right: 20px;
  bottom: 20px;
}
.stream_ongoing > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.connect_footer {
  background-color: #6950ba;
  border-radius: 12px;
  padding: 16px;
  margin-top: 20px;
}
.connect_actions button {
  background-color: #ffffff30;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  transition: all 0.1s ease;
}
.connect_actions button:hover {
  background-color: #ffffff50;
}
.connect_actions button.active {
  background-color: #fff;
}
.connect_actions button.active img {
  filter: brightness(0.3);
}
.connect_actions button.active:hover {
  background-color: #f4f4f4;
}
.connect_chat {
  background-color: #f3f5f8;
  border: 2px solid #f3f5f8;
  border-radius: 12px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}
.connect_chat.visible {
  /* max-height: 500px; Adjust this value according to your content */
  animation: slideIn 0.3s ease forwards;
}
@keyframes slideIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 900px; /* Adjust this value according to your content */
  }
}
.chat_content {
  height: calc(100vh - 258px);
  margin: 16px 0;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #bbb transparent;
}
.chat_content > h6 {
  color: #201838;
  font-size: 18px;
  font-weight: 700;
}
.connect_chat.prescription_list .chat_content {
  height: calc(100vh - 182px);
  margin: 16px 0;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #bbb transparent;
}
.connect_chat.prescription_list .chat_content > div {
  margin: 0;
}
.connect_chat.prescription_list .chat_reveive_precription {
  max-width: 100%;
}

.chat_content::-webkit-scrollbar {
  height: 3px !important;
  width: 5px !important;
}

.chat_content::-webkit-scrollbar-track {
  background: transparent;
}

.chat_content::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border: transparent;
  border-radius: 25px;
}
.chat_content .chat_reveive_precription {
  max-width: 100%;
}
.chat_content .chat_send_side {
  max-width: 88%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.chat_content .chat_reveive_side p {
  background-color: #b7e0ff;
  color: #000;
  margin-bottom: 5px;
  padding: 14px;
  font-size: 14px;
  width: fit-content;
  border-radius: 2px 10px 10px 2px;
}
.chat_content .chat_reveive_side p:nth-last-child(2) {
  border-radius: 2px 10px 10px 10px;
}
.chat_content .chat_reveive_side p:first-child {
  border-radius: 10px 10px 10px 2px;
}
.chat_content .chat_reveive_precription > div {
  /* background-color: #fff; */
  background-color: #b7e0ff;
  color: #000;
  margin-bottom: 5px;
  padding: 14px;
  font-size: 14px;
  width: 100%;
  border-radius: 2px 10px 10px 2px;
}
.chat_content .chat_reveive_precription > div:nth-last-child(2) {
  border-radius: 2px 10px 10px 10px;
}
.chat_content .chat_reveive_precription > div:first-child {
  border-radius: 10px 10px 10px 2px;
}
.chat_content .precription_detail {
  display: flex;
  gap: 12px;
  align-items: center;
}
.chat_content .precription_detail > img {
  border-radius: 12px;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.chat_content .precription_detail p {
  font-weight: 700;
  color: #201838;
  margin-bottom: 0;
  padding: 0;
  background: transparent;
}
.chat_content .precription_detail span {
  display: flex;
  gap: 4px;
  font-size: 13px;
  color: #635d74;
  margin-top: 5px;
}
.chat_content .chat_send_side p {
  background-color: #6950ba;
  color: #fff;
  margin-bottom: 5px;
  padding: 14px;
  font-size: 14px;
  width: fit-content;
  border-radius: 10px 2px 2px 10px;
}
.chat_content .chat_send_side p:nth-last-child(2) {
  border-radius: 10px 2px 10px 10px;
}
.chat_content .chat_send_side p:first-child {
  border-radius: 10px 10px 2px 10px;
}
.chat_content .chat_send_side span {
  margin-left: auto;
  font-size: 14px;
}
.chat_action {
  background-color: #fff;
  display: flex;
}
.chat_action button {
  background-color: #fff;
  padding: 25px 10px;
  border: none;
}
.chat_action input {
  background-color: #fff;
  padding: 25px 0;
  border: none;
  width: 100%;
  font-weight: 500;
}
.chat_action input:placeholder-shown {
  font-weight: 400;
}
.chat_action input:focus-visible {
  outline: none;
}
.connect_chat {
  /* Your existing styles */
  overflow-y: auto; /* Enable vertical scrolling */
}

.chat_content {
  display: flex;
  flex-direction: column-reverse; /* Display flex items in reverse order */
}

.chat_content > div {
  margin-bottom: 10px; /* Add margin between chat messages */
}

/* connect end */

/* payment prescription start */
.prescription_container {
  padding: 0;
}
.prescription_header {
  background-color: #6950ba;
  padding: 20px;
  border-radius: 12px 12px 0 0;
}
.prescription_header {
  background-color: #6950ba;
  padding: 20px;
  border-radius: 12px 12px 0 0;
}
.prescription_header h6 {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  line-height: 22px;
}
.prescription_header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.prescription_header p {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  word-break: break-word;
}
.prescription_header > div > div {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}
.prescription_content {
  padding: 20px;
  border-radius: 0 0 12px 12px;
}
.extra_book_card {
  display: flex;
  align-items: center;
  gap: 10px;
}
.extra_book_card > img {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
}
.extra_book_card > div {
  margin-right: auto;
}
.extra_book_card > div span {
  margin-right: auto;
  color: #201838;
  font-size: 18px;
  font-weight: 700;
}
.extra_book_card > div p {
  color: #201838;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.procedure_box > span,
.remark_box > span {
  font-weight: 700;
  color: #1c1c21;
}
.procedure_box > div {
  display: flex;
  align-items: flex-start;
}
.procedure_box > div > span {
  width: 120px;
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  margin: 16px 0 0;
}
.procedure_box p,
.remark_box p {
  font-weight: 600;
  font-size: 14px;
  color: #1c1c21;
  margin: 16px 0 0;
}
/* payment prescription end */
.row_scroll_x {
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  padding-bottom: 1rem;
}

@media screen and (max-width: 1399px) {
  .navbar_search_box {
    width: unset;
  }
  .contact_content h5 {
    padding: 0 8rem;
  }
  .about_content p,
  .contact_content p {
    color: #635d74;
    line-height: 24px;
    text-align: center;
    padding: 0 30px;
    margin: 0;
  }
}

@media screen and (max-width: 1240px) {
  .slider_content {
    height: 600px;
  }

  .slider_content img {
    height: 100%;
    object-fit: cover;
  }
  .product_shop_card > h5 {
    padding: 0;
  }
}

@media screen and (max-width: 1199px) {
  .report_card .report_card_content,
  .register_card .register_card_content {
    padding-left: 12px;
  }

  .report_card h2,
  .register_card h2 {
    font-size: 26px;
  }

  .footer_app_card {
    max-height: 100%;
    margin-bottom: -96px;
  }

  .account_page .tab_content_box .pet_extrainfo > div > div h6 {
    font-size: 16px;
  }
  .contact_content h5 {
    padding: 0 5rem;
  }
  .footer_app_card_wrapper .footer_app_card_info p {
    width: 75%;
  }
}

@media screen and (min-width: 992px) {
  .container.custom_container_fluid {
    max-width: unset;
  }

  .navbar_top,
  nav.navbar {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .row_md_scroll {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }
  .navbar_top,
  .navbar_location_search,
  .navbar_links {
    display: none;
  }

  nav.navbar {
    padding: unset;
    height: 66px;
  }

  .navbar-brand > div > img {
    height: 36px !important;
  }

  nav.navbar .navbar-brand svg {
    width: 100px;
  }

  .humburger_menu {
    display: unset;
    background: none;
    border: none;
    transition: all 0.3s ease;
  }

  .humburger_menu span {
    display: block;
    height: 3px;
    width: 25px;
    background-color: #6950ba;
    margin: 5px 0 5px auto;
    border-radius: 4px;
    transition: all 0.3s ease;
  }

  .humburger_menu .line_1 {
    width: 20px;
  }

  .humburger_menu .line_3 {
    width: 15px;
  }

  .humburger_menu.menu_nav_active .line_1 {
    width: 25px;
    transform: scale(-1) rotate(135deg) translateY(5px);
  }

  .humburger_menu.menu_nav_active .line_2 {
    display: none;
  }

  .humburger_menu.menu_nav_active .line_3 {
    width: 25px;
    transform: rotate(45deg) translateY(-5px);
  }

  .navbar_location_search_sm {
    display: block;
    background-color: #6950ba;
  }

  .navbar_cart_box_sm a {
    margin: 0 0 0 30px;
    position: relative;
  }

  .navbar_location_search_sm .navbar_search_box {
    background-color: #ffffff !important;
    border-radius: 8px;
  }

  .navbar_location_box_sm {
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .navbar_location_box_sm span {
    font-size: 17px;
    font-weight: 600;
  }

  .navbar_cart_box_sm span {
    cursor: pointer;
  }

  .navbar_search_box input {
    padding: 10px 10px 10px 50px;
    cursor: pointer;
  }

  .navbar_search_box_dropdown.dropdown-menu {
    margin-left: 0;
    width: 100%;
    max-height: calc(400px);
  }
  .offcanvas-backdrop,
  .offcanvas.offcanvas-end {
    top: 65px !important;
  }
  .stream_chat_responsive.offcanvas.offcanvas-end {
    top: 0 !important;
  }

  .offcanvas-backdrop.show {
    opacity: 0.4 !important;
  }

  .navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown {
    left: 0 !important;
    right: 0 !important;
    bottom: 55px !important;
    width: 100%;
    transform: none !important;
  }

  .nav_links_sm {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav_links_sm li {
    padding: 10px 0;
    color: #1c1c21;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .nav_links_sm li.navlink_dropdown_btn {
    padding: 0;
  }

  .navlink_dropdown_btn .navlink_dropdown_wo_icon {
    max-width: unset;
    width: 100%;
    border-radius: 0 0 12px 12px;
    border: 1px solid #ddd;
    border-top: none;
    border-top: none;
    box-shadow: 0px 10px 15px #eee;
  }

  .nav_links_sm li.navlink_dropdown_btn .dropdown-toggle {
    padding: 10px 0;
    color: #1c1c21;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .nav_links_sm li.navlink_dropdown_btn .dropdown-toggle::after {
    content: "";
    height: 16px;
    padding: 4px 8px;
    border: none;
    background-color: transparent;
    appearance: none;
    background-image: url(./assets/svg/arrow_down_primary.svg);
    background-repeat: no-repeat;
    filter: saturate(0);
  }

  .navlink_dropdown_btn .navlink_dropdown_list {
    max-width: unset;
    width: 100%;
    border-radius: 0 0 12px 12px;
    border: 1px solid #ddd;
    border-top: none;
    border-top: none;
    box-shadow: 0px 10px 15px #eee;
  }

  .navlink_dropdown_btn .navlink_dropdown_multiplelist {
    max-width: unset;
    width: 100%;
  }

  .navlink_dropdown_btn .navlink_dropdown_multiplelist.show {
    flex-direction: column;
    border-radius: 0 0 12px 12px;
    border: 1px solid #ddd;
    border-top: none;
    padding-bottom: 5rem;
    border-top: none;
    box-shadow: 0px 10px 15px #eee;
  }

  .navlink_dropdown_btn .navlink_dropdown_multiplelist .navlink_dropdown_list {
    border: none;
    box-shadow: none;
  }

  /* main section start */
  .main {
    margin-top: 0;
  }

  /* main section end */

  .md-mb-3 {
    margin-bottom: 1rem;
  }

  .hero_section {
    margin-top: 0;
  }

  .veterinary_card_container > .row {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }

  .shop_by_pet_card,
  .shop_by_health,
  .shop_by_category {
    max-height: unset;
    height: 100%;
  }

  .shop_by_pet_card img,
  .shop_by_health img,
  .shop_by_category img {
    width: auto;
    position: absolute;
    right: 0;
  }

  .services_card {
    padding: 10px;
    height: 130px;
  }

  .services_card span {
    text-align: center;
    margin-top: 10px;
  }

  .category_services_card {
    padding: 24px;
  }

  .report_card h2,
  .register_card h2 {
    font-size: 23px;
  }

  .report_card p,
  .register_card p {
    line-height: 20px;
  }

  /* footer start */
  .footer_app_card_svgimg {
    max-width: 120px;
    margin: auto 0;
  }

  .footer_app_card_svgimg img {
    width: 100%;
  }

  .footer_app_card div.card .footer_app_card_info h4 {
    max-width: 300px;
  }

  .footer_app_card div.card .footer_app_card_info > div img {
    max-width: 100px;
    width: 100%;
  }

  .footer_app_card .footer_app_card_info a + a img {
    margin-left: 1rem;
  }

  .footer_app_card_qrimg {
    max-width: 100px;
  }

  .footer_app_card_qrimg img {
    width: 100%;
  }

  .footer_logo {
    width: 100px;
    margin-top: 64px;
  }

  div.footer_links {
    margin-top: 5rem;
  }

  /* footer end */

  /* my-profile start */
  /* .account_page .tab_content_box {
    margin-top: 1rem;
  } */
  .manage_address_list .manage_address_action {
    width: 100%;
    margin-top: 1rem;
  }

  .manage_address_list .manage_address_action button {
    width: 100%;
  }

  /* my-profile end */
  /* my-consultation start */
  .consultation_list .past_consultation_list .btn_primary_outline_box {
    width: 100%;
    margin: 20px 0 0;
  }

  .consultation_list .status_badge {
    width: 50%;
    margin: auto 16px 0 0;
  }

  .consultation_list .status_badge + .btn_primary_outline_box {
    width: 50%;
  }

  /* my-consultation end */
  /* my-orders start */
  .myorders_list .order_price {
    order: 4;
  }

  .myorders_list .order_items {
    order: 3;
  }

  .myorders_list .order_date > span {
    margin: 4px 0 16px;
  }

  /* my-orders end */
  .add_new_pet_select_pet > div img {
    max-width: 35px;
  }

  /* filter page start */
  .filter_page_left_side button {
    cursor: pointer;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .filter_page_left_side button:hover img {
    filter: brightness(5);
  }

  .filter_page_left_side img {
    max-width: 25px;
    max-height: 25px;
  }

  /* filter page end */
  /* doctor detail page start */
  .doctor_info_card .doctor_info_card_name h6 {
    margin-top: 1rem;
  }

  /* doctor detail page end */
  .about_content,
  .contact_content {
    margin: 2rem 0 0;
    padding: 2rem 1rem;
  }
  /* connect start */
  .stream_chat_responsive.offcanvas {
    background: #f3f5f8;
  }
  .stream_chat_responsive .offcanvas-body {
    overflow-x: hidden;
  }
  .stream_chat_responsive .connect_chat {
    border-radius: 0;
  }
  .stream_chat_responsive .chat_content {
    height: calc(100vh - 174px);
  }
  /* connect end */

  .prescription_header > div {
    flex-direction: column;
  }
  .prescription_header > div > div {
    width: 100%;
  }
  .prescription_header > div > div button {
    width: 100%;
  }
  .procedure_box > div {
    flex-direction: column;
  }
  .procedure_box > div p {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product_offer_card > span {
    margin: 10px 0 25px;
  }

  .product_offer_card button {
    width: 100%;
  }

  .consultation_list .btn_primary_outline_box {
    width: 100%;
    margin: 20px 0 0;
  }

  /* order-detail start */
  .order_detail_item_ordered .order_detail_product_name {
  }

  .order_detail_item_ordered .order_detail_product_price {
    order: 4;
  }
  .order_detail_item_ordered .cart_product_price {
    order: unset !important;
  }

  .order_detail_item_ordered .order_detail_product_desc {
  }

  .order_detail_item_ordered .order_detail_product_quantity {
  }

  /* order-detail end */
}

@media screen and (max-width: 767px) {
  .shop-by.row {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }
  .breadcrumb img {
    width: 100%;
  }

  .footer_app_card_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column !important;
    align-items: center;
  }

  .carousel ul.control-dots {
    bottom: 0;
    .dot {
      background: #ddd;
    }
  }

  .slider-wrapper.axis-horizontal {
    margin-bottom: 24px !important;
  }

  .slider_content {
    height: 400px;
    padding: 12px;
    border-radius: 12px;
    img {
      object-fit: fill;
      border-radius: 12px;
    }
  }

  .service_veterinary {
    transform: translateY(16px);
  }

  .slider_info h1 {
    font-size: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .slider_info p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .slider_content .slider_image img {
    object-fit: contain;
  }

  .services_section > h2 {
    font-size: 30px;
  }

  .veterinary_card_container {
    padding: 1rem !important;
  }

  .veterinary_card {
    padding: 20px 0 10px;
  }

  .veterinary_card img {
    max-width: 45px;
  }

  .veterinary_card span {
    font-size: 14px;
  }

  .shop_by_pet_card,
  .shop_by_health,
  .shop_by_category {
    height: 250px;
  }

  .shop_by_pet_card img,
  .shop_by_health img,
  .shop_by_category img {
    width: auto;
    right: unset;
    transform: translateX(-50%);
    left: 50%;
  }

  .shop_by_health img {
    width: 100%;
    right: unset;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
  }

  .services_section .category_services_card:first-child,
  .services_section .category_services_card:nth-child(2),
  .services_section .category_services_card:nth-child(3),
  .services_section .category_services_card:first-child {
    border-radius: 0;
  }

  .offer_section h3 {
    font-size: 30px;
  }

  .report_register_section {
    overflow: hidden;
  }

  .report_card,
  .register_card {
    max-height: 275px;
  }

  /* .report_card div.h-100, */
  .register_card .register_card_image {
    height: inherit;
    overflow: hidden;
  }

  .blog_post_section h3,
  .explore_video_section h3 {
    font-size: 28px;
  }

  /* footer start */
  .footer_app_card .container {
    max-width: unset;
  }

  .footer_app_card .footer_app_card_info {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column !important;
    align-items: center;
  }

  .footer_app_card div.card .footer_app_card_info h4 {
    font-size: 20px;
  }

  .newsletter_input {
    max-width: unset;
  }

  /* footer end */
  .social_mobile_login {
    flex-direction: column-reverse;
  }

  .social_auth_login {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .social_auth_login li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social_auth_login li + li {
    margin-top: 0;
  }

  .social_auth_login li span {
    display: none;
  }

  .auth_option_divider {
    margin: 3rem auto;
    width: 100%;
    height: 1px;
  }

  .auth_option_divider::after {
    width: 50px;
    height: 35px;
  }
  .contact_content h5 {
    padding: 0px 3rem;
  }
  .about_content p,
  .contact_content p {
    padding: 0;
  }
  /* connect start */
  .stream_chat_responsive .chat_content {
    height: calc(100vh - 190px);
  }
  /* connect end */
  .footer_app_card {
    margin-bottom: -142px;
}
}

@media screen and (min-width: 576px) {
  .modal-dialog.modal-sm {
    --bs-modal-width: 370px;
  }
}

@media screen and (max-width: 575px) {
  .navbar_search_box_dropdown.dropdown-menu {
    min-width: 100%;
  }

  .doctors_wrapper,
  .blog_wrapper {
    flex-direction: column;
  }
  .blog_wrapper .blog_box,
  .doctors_box {
    width: 100%;
  }

  .slider_content {
    height: 320px;
  }

  .slider_content .container {
    top: 50%;
    left: 54%;
  }

  .slider_info h1 {
    font-size: 28px;
  }

  .slider_info p {
    margin: 10px 0 24px;
    font-size: 14px;
    line-height: 18px;
  }

  .slider_info .btn_light {
    padding: 8px 30px;
    font-weight: 600;
    font-size: 13px;
  }

  .veterinary_card_container {
    border-radius: 0;
  }

  .shop_by_health img {
    width: 100%;
    right: unset;
    transform: translateX(-50%);
    left: 50%;
    top: unset;
    bottom: 0;
  }

  .product_offer_card > span {
    margin: 10px 0 25px;
  }

  .product_offer_card button {
    width: 100%;
  }

  .report_card,
  .register_card {
    max-height: unset;
  }

  .report_card div.h-100,
  .register_card div.h-100 {
    height: initial !important;
  }

  .report_card_image > div,
  .register_card_image > div {
    margin: 0 auto 1rem;
  }

  .report_card .report_card_content,
  .register_card .register_card_content {
    padding-left: 12px;
    align-items: center;
  }

  .report_card p,
  .register_card p {
    text-align: center;
  }

  /* footer start */
  .footer_app_card {
    margin-bottom: -312px;
  }

  .footer_app_card .flex-row {
    flex-direction: column !important;
  }

  .footer_app_card_svgimg {
    max-width: 180px;
  }

  .footer_app_card .footer_app_card_info {
    width: 100%;
    margin: 1.5rem 0;
  }

  .footer_app_card div.card .footer_app_card_info h4 {
    max-width: unset;
    text-align: center;
  }
  .footer_app_card div.card .footer_app_card_info p {
    max-width: unset;
    text-align: center;
    width: 75%;
  }

  .footer_app_card div.card .footer_app_card_info div {
    display: flex;
    justify-content: center;
  }

  .footer_app_card_qrimg {
    max-width: 130px;
  }

  footer section {
    padding: 400px 0 160px;
  }

  div.footer_links + div.footer_links {
    margin-top: 3rem;
  }

  /* footer end */

  .consultation_list .btn_primary_outline_box {
    width: 100%;
    margin: 20px 0 0;
  }

  .order_detail_item_ordered .order_detail_product_price {
    order: 4;
  }
  .cart_quantity_action_btn,
  .cart_product_price {
    margin-top: 8px !important;
  }
  .cart_quantity_action_btn > .ms-auto {
    margin-left: 0 !important;
  }
  .rate_review_modal .modal-content {
    width: unset;
    margin: auto;
  }
  .contact_content h5 {
    padding: 0px;
  }
  .order_detail_item_ordered .order_detail_product_name p {
    font-size: 16px;
  }
  .order_detail_item_ordered .rate_review_link_btn p {
    font-size: 14px;
  }
}
@media screen and (max-width: 479px) {
  .evidence_image_upload {
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start !important;
  }
  .extra_book_card {
    flex-wrap: wrap;
    gap: 18px;
  }
  .extra_book_card button {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .prescription_header > div > div {
    flex-direction: column;
  }
}

#recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
}

.custom-class {
  border-color: #007bff; /* Example border color for the checked state */
  background-color: #c5d1dd; /* Example background color for the checked state */
  color: #fff; /* Example text color for the checked state */
}

#recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
}

.custom-class {
  border-color: #007bff; /* Example border color for the checked state */
  background-color: #c5d1dd; /* Example background color for the checked state */
  color: #fff; /* Example text color for the checked state */
}
.loader_primary.spinner-border {
  border-color: #6950ba;
  border-right-color: transparent !important;
}

.coupon-applied-message {
  font-size: 16px;
  color: black; /* A subtle green color to indicate success */
  margin-top: 5px;
}

/* Vidio Player Css */
.video-player-container {
  display: flex;
  gap: 20px;
}

.video-player {
  flex: 2;
  /* position: relative; */
  position: sticky;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: white;
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: add shadow for better visibility */
}

.close-button:hover {
  background-color: #f0f0f0; /* Optional: change background on hover */
}

.video-suggestions {
  flex: 1;
  overflow-y: auto;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.suggestion {
  display: flex;
  cursor: pointer;
}

.suggestion-thumbnail {
  border-radius: 13px;
  width: 120px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
}

.suggestion-details {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.suggestion h4 {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

.suggestion p {
  margin: 0;
  font-size: 12px;
  color: gray;
}

.suggestion-details span {
  color: #6950ba;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  letter-spacing: -0.2px;
}

.bloodtestreport {
  border: 1px solid #e4e3e7;
  border-radius: 10px;
}

/* --------------------------------- Refer And Earn --------------------------------- */

.refer_earn .refer_earn_wrapper {
  background-color: #6950ba;
  margin: 30px 0;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 30px;
}
.refer_earn_wrapper .main_img {
  max-width: 300px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refer_earn_wrapper .main_img img {
  width: 100%;
}
.refer_text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.refer_earn_wrapper .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  margin: 0px 0 20px;
}
.refer_earn_wrapper .content {
  font-size: 26px;
  font-weight: 600;
  line-height: 34px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 45px;
}
.refer_earn_wrapper .code_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refer_earn_wrapper .code_wrapper {
  background-color: #fff;
  border-radius: 18px;
  padding: 20px 16px;
}
.refer_earn_wrapper .code_wrapper .code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px dashed #6950ba;
  padding: 18px 20px;
  border-radius: 8px;
  background-color: #f8f5ff;
  margin-bottom: 25px;
}
.refer_earn_wrapper .code_wrapper .code .code_content {
  margin-right: 150px;
}
.refer_earn_wrapper .code_wrapper .code .code_content .code_text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #5856d6;
}
.refer_earn_wrapper .code_wrapper .code .code_content .code_copy {
  font-size: 12px;
  line-height: 18px;
  color: #49657b;
}
.refer_earn_wrapper .code_wrapper .code .icon button {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
}
.refer_earn_wrapper .code_wrapper .code .icon button img {
  width: 20px;
}
.refer_earn_wrapper .code_wrapper .action button {
  width: 100%;
  background-color: #6950ba;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 13px 20px;
  border: 0;
}

@media (max-width: 1200px) {
  .refer_text {
    text-align: center;
    width: 60%;
    margin: 0 auto;
  }
  .refer_text .title {
    width: 100%;
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .refer_text {
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .refer_earn .refer_earn_wrapper {
    padding: 20px 0 0;
  }
  .refer_earn_wrapper .code_wrapper {
    width: 100%;
  }
  .refer_text {
    padding: 0 10px;
  }
  .refer_earn_wrapper .content {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .refer_earn_wrapper .main_img {
    padding: 0 20px;
  }
  .refer_earn_wrapper .code_wrapper .code .code_content {
    margin-right: 0;
  }
}

/* -------------------------- Comment Page -------------------------- */

.comment_container {
  padding: 50px 0;
}
.comment_container .comment_wrapper:last-child {
  border-bottom: 0;
}
.comment_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #f8f5ff;
  padding: 12px 0;
}
.comment_wrapper .avtar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}
.comment_wrapper .avtar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment_wrapper .content_wrapper {
  width: 100%;
}
.comment_wrapper .content_wrapper:last-child {
  border-bottom: 0;
}
.comment_wrapper .content_wrapper .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment_wrapper .content_wrapper .top .name {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.25px;
  text-align: left;
  color: #1d242c;
}
.comment_wrapper .content_wrapper .top .time {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.25px;
  text-align: left;
  color: #7e879f;
}
.comment_wrapper .bottom {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.5px;
  text-align: left;
  color: #8a8792;
  margin-top: 7px;
}

.add_comment {
  border: 1px solid #d6cded;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 15px;
  margin-top: 50px;
}
.add_comment input {
  width: 100%;
  border: 0;
  outline: none;
}
.add_comment img {
  cursor: pointer;
}

@media (max-width: 1600px) {
}

.comment_edit_button {
  border-radius: 50px;
  border: none;
  margin-left: 4px;
}
.three-dots-button::after {
  display: none !important; /* Hide the default arrow indicator */
}

.banner_image {
  width: 100%;
  height: 80vh; /* Set a consistent height for all banner images */
  object-fit: cover; /* Ensure the image covers the container without distortion */
}

/* CongratulationsModal.css */
.congrats-modal .modal-content {
  height: "100%";
  border-radius: 20px;
  padding: 20px;
  font-family: "Arial", sans-serif;
  border: none;
  position: relative;
  z-index: 1051; /* Ensure modal content is above the confetti */
  overflow: hidden; /* Ensure confetti doesn't overflow the modal */
}

.congrat-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #3b5998; /* Adjust color as needed */
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1052; /* Ensure button is above the confetti */
}

.confetti {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050; /* Ensure confetti is behind the modal content but within the modal */
}

.congrats-title {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  width: 100%;
  color: #3b5998; /* Adjust color as needed */
  margin-bottom: 20px;
}

.congrats-body {
  font-size: 1.25rem;
}

.coupon_modal_container .modal-content {
  border-radius: 9px;
  padding: 45px;
}

.congrats-body.coupon_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.coupon_modal_close {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 26px;
  line-height: 30px;
  font-weight: 500;
  color: #000;
}

.coupon_modal .coupon_image {
  width: 127px;
  height: 127px;
  border-radius: 50%;
}
.coupon_modal .coupon_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.congrats_title {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  color: #6950ba;
  margin: 20px 0 10px;
  text-transform: capitalize;
}

.savings_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #1b202e;
  margin-bottom: 20px;
}

.thanks_text {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #f3be56;
  margin-bottom: 0;
}

.savings-text {
  font-weight: bold;
  color: #3b5998; /* Adjust color as needed */
}

.thanks-text {
  margin-top: 20px;
  color: #ff6347;
  font-weight: bold;
}

/* time peaker */
/* Ensure the input field is clickable */
.input_time .time-picker {
  pointer-events: auto; /* Allow interactions with the picker */
}

.input_time .time-picker input {
  cursor: pointer; /* Show pointer cursor to indicate clickable field */
}

/* Hide the input field so users can't type */
.input_time .time-picker input {
  color: transparent; /* Make text transparent to hide any manual input */
  text-shadow: 0 0 0 #000; /* Preserve the visual placeholder */
}

.input_time .time-picker input::placeholder {
  color: #000; /* Set placeholder color if needed */
}



/* form after effect remover */
.remove-after::after {
  content: none;
  display: none;
}

.modal-footer .btn {  
  margin: 0 10px; 
}


/* ---------------Main modal wrapper---------------- */
.join-call-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Modal content */
.join-call-modal .modal-content {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

/* Modal body */
.join-call-modal-body {
  padding: 20px;
}

.join-call-modal-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 2vh;
}

.join-call-modal-text {
  font-size: 16px;
  color: #555;
}

/* Modal footer */
.join-call-modal-footer {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

/* Decline button */
.join-call-modal-decline {
  width: 100px;
  background-color: red;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.join-call-modal-decline:hover {
  background-color: darkred;
}

/* Join button */
.join-call-modal-join {
  width: 100px;
  background-color: green;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.join-call-modal-join:hover {
  background-color: darkgreen;
}
